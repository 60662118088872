import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import partner from "./modules/partner";
import admin from "./modules/admin";
import direct from "./modules/direct";
import slotegator from "./modules/slotegator";
import affiliate from "./modules/affiliate";
import casino from "./modules/casino";
import app from "./modules/app"
Vue.use(Vuex);

const debug = process.env.VUE_APP_NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    user,
    partner,
    admin,
    direct,
    slotegator,
    affiliate,
    casino,
    app
  },
  strict: false,
});