const mutations = {
    GET_DASHBOARD_TOTAL_BY_DATE: (state, payload) => {
        state.dashboardtotalbydate = payload;
    },
    GET_DASHBOARD_TOTAL_GRAPH_BY_DATE: (state, payload) =>{
        state.dashboardGraphTotal = payload;
    },
    GET_DASHBOARD_PAYOUT_HISTORY: (state, payload) =>{
        state.dashboardpayouthistory = payload;
    },
    SET_ERROR: (state, payload) => {
        state.error = payload;
    },
    GET_AFFILIATE_USER: (state, payload) => {
        state.affiliateuser = payload;
    }
};
  
export default mutations;