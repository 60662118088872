const mutations = {
    GET_USERS: (state, payload) => {
        state.getusers = payload;
    },
    GET_ADMIN_PARTNER_DEPOSIT_TRANSACTIONS: (state,payload) => {
        state.adminpartnerdeposittransactions = payload;
    },
    GET_ADMIN_PARTNER_CASHOUT_TRANSACTIONS: (state,payload) => {
        state.adminpartnercashouttransactions = payload;
    },
    GET_ADMIN_USERS_DEPOSIT_TRANSACTIONS: (state,payload) => {
        state.adminusersdeposittransactions = payload;
    },
    GET_ADMIN_USERS_CASHOUT_TRANSACTIONS: (state,payload) => {
        state.adminuserscashouttransactions = payload;
    },
    GET_TRANSACTION_DISCREPANCIES: (state,payload) => {
        state.transactiondiscrepancies = payload;
    },
    GET_ADMIN_PARTNER: (state,payload) => {
        state.adminpartnerlist = payload;
    },
    GET_PARTNER_AFFILIATES: (state,payload) => {
        state.adminpartneraffiliates = payload;
    },
    GET_PARTNER_SUBUSERS: (state,payload) => {
        state.adminpartnersubusers = payload;
    },
    GET_PARTNER_SUBPARTNERS: (state,payload) => {
        state.adminpartnersubpartners = payload;
    },
    GET_ADMIN_PARTNER_ADMIN_DEPOSIT_TRANSACTIONS: (state,payload) => {
        state.adminpartneradmindeposit = payload;
    },
    GET_ADMIN_PARTNER_ADMIN_CASHOUT_TRANSACTIONS: (state,payload) => {
        state.adminpartneradmincashout = payload;
    },
    SET_ERROR: (state, payload) => {
        state.error = payload;
    },
    SET_SITE_SETTINGS: (state, payload) => {
        state.siteSettings = payload;
    },
    GET_BADGES: (state, payload) => {
        state.getbadges = payload;
    },
    GET_PARTNER_PAYOUT_ADMIN: (state, payload) => {
        state.partnerpayoutadmin = payload;
    },
    SET_GENERATE_PARTNER_PAYOUT: (state, payload) => {
        state.generatepayoutpartner = payload;
    },
    GET_ADMIN_AFFILIATE: (state, payload) => {
        state.adminaffiliate = payload
    },
    GET_AFFILIATE_PAYOUT_DATA: (state, payload) => {
        state.affiliatepayoutdata = payload
    },
    SET_GENERATE_AFFILIATE_PAYOUT: (state, payload) => {
        state.generatepayoutaffiliate = payload;
    },
    SET_ALL_PARTNER_PAYOUT: (state, payload) => {
        state.partnerpayout = payload;
    },
    GET_PARTNER_PAYOUT_BULK_ADMIN: (state, payload) => {
        state.partnerpayoutbulk = payload;
    },
    GET_GENEREATE_PAYOUT_BULK_ADMIN: (state, payload) => {
        state.generatepayoutbulk = payload;
    },
    UPDATE_PAYOUT_BULK_ADMIN: (state, payload) => {
        // const temp = state.generatepayoutbulk;
        state.generatepayoutbulk[payload.index].transfer_credits = payload.data;
    },
    GET_DOWNLOAD_EXCEL_PAYOUT_BULK_ADMIN: (state, payload) => {
        state.downloadexcelpayoutbulk = payload;
    },
    GET_AFFILIATE_PAYOUT: (state, payload) => {
        state.affiliatepayout = payload;
    },
    GET_MARKETING_USERS: (state,payload) => {
        state.marketingusers = payload;
    },
    GET_GOOGLE_SHEETS: (state,payload) => {
        state.googlesheets = payload;
    },
    GET_AFFILIATE_COMMISSIONS_LIST: (state,payload) => {
        state.affiliatecommissionlist = payload;
    }
};
  
export default mutations;