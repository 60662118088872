import store from "../../store/";
import jwtDecode from "jwt-decode";
import Vue from "vue";
const tokenData = () => {
  const hasToken = Vue.$cookies.isKey("_token");
  if (hasToken) {
    const token = Vue.$cookies.get("_token");
    const decodedToken = jwtDecode(token);
    const currentTime = Math.ceil(new Date().getTime() / 1000);
    const remaining = decodedToken.exp ? decodedToken.exp - currentTime : 0;
    return { hasToken, token, decodedToken, currentTime, remaining };
  }
  return { hasToken };
};

export default async (to, from, next) => {
  let token = tokenData();
  // console.log("tokeeeen", token);
  if (token.hasToken) {
    if (token.remaining <= -1800) {
      await store.dispatch("user/logout");
      return next("/");
    }
    if (token.remaining <= 2) {
      const response = await store.dispatch("user/userValidate");
      // console.log("Validate", response);
      if (response && response.data && !response.data.success) {
        Vue.$cookies.remove("_token");
        if (to.name == "Home") return next();
        else{
          return next("/");  
        } 
      }else{
        return next();
      }
    }else{
      return next();
    }
    
  }
  if(to.meta.auth){
    return next("/");
  }
  next();
};
