const actions = {
  async login({}, payload) {
    try {
      console.log("payload", payload);
      const data = await this._vm.$authApi.post("/login", payload);
      console.log("daaaaataa", data);
      return data;
    } catch (er) {
      return er;
    }
  },

  async fbLogin({}, payload) {
    try {
      console.log("payload fb", payload);
      const data = await this._vm.$authApi.post("/fb-login", {
        auth: {
          authResponse: payload.authResponse,
          status: payload.status,
        },
        voucher_code: payload.voucher_code,
        partner_link: payload.partner_link,
        ip_address: payload.ip_address
      });
      return data.data;
    } catch (er) {
      console.log("error fb", er);
      return er;
    }
  },

  async impLogin({}, payload) {
    try {
      console.log("payload imp login", payload);
      const data = await this._vm.$authApi.post(`/admin/imp/${payload.id}`,payload);
      console.log("daaaaataa", data);
      return data;
    } catch (er) {
      return er;
    }
  },

  async register({}, payload) {
    try {
      console.log("PAYLOADSS",payload)
      const data = await this._vm.$authApi.post("/register", payload);
      if (data.data.success) {
        const login = await this._vm.$authApi.post("/login", {
          email: payload.email,
          password: payload.password,
          ip_address: payload.ip_address,
        });
      }
      return data;
    } catch (er) {
      return er;
    }
  },

  async logout() {
    try {
      await this._vm.$authApi.post("/logout");
      return (location.href = "/home?bt-path=%2Flive");
    } catch (er) {
      return er;
    }
  },

  async userValidate() {
    try {
      return await this._vm.$authApi.post("/validate");
    } catch (err) {}
  },

  async updateUserProfile({}, payload) {
    try {
      return await this._vm.$authApi.post(
        `/user/account/${payload.id}`,
        payload
      );
    } catch (err) {}
  },

  async getUserEarnings({ commit }, payload) {
    try {
      const res = await this._vm.$authApi.get(
        `/user/earnings/${payload}`,
        payload
      );
      const { data } = res;
      commit("GET_USER_EARNINGS", data);
    } catch (error) {
      return error;
    }
  },

  async checkPassword({}, payload) {
    try {
      const res = await this._vm.$authApi.post(
        `/user/account/confirm-password/${payload.id}`,
        payload
      );
      return res;
    } catch (error) {
      return error;
    }
  },

  async changeAccountPassword({}, payload) {
    try {
      const res = await this._vm.$authApi.post(
        `/user/account/password/${payload.id}`,
        payload
      );
      return res;
    } catch (error) {
      return error;
    }
  },

  async claimGiftCode({}, payload) {
    try {
      const res = await this._vm.$authApi.post(`/user/claim-gc`, payload);
      return res.data;
    } catch (error) {
      return error;
    }
  },

  async getUserGiftCode({}, payload) {
    try {
      const limit = payload && (payload.limit || 10);
      const search = payload && (payload.search || "");
      const page = payload && (payload.page || 1);
      const res = await this._vm.$authApi.get(
        `/user/gift-codes/${payload.id}`,
        {
          params: {
            search,
            limit,
            page,
          },
        }
      );
      return res.data;
    } catch (error) {
      return error;
    }
  },

  async getUserInfo({ commit }, payload) {
    try {
      const response = await this._vm.$authApi.get(
        `/user/account/info/${payload}`,
        payload
      );
      const { data } = response;
      commit("SET_USER_DATA", data);
    } catch (error) {
      return error;
    }
  },
  async sendResetLink({}, payload) {
    try {
      const response = await this._vm.$authApi.post("/send-email", payload);
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  },
  async postResetPassword({}, payload) {
    try {
      const response = await this._vm.$authApi.post(
        `/forgot-password?token=${payload.token}`,
        {
          password: payload.password,
        }
      );
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  },
  async sendActivationLink({}, payload) {
    try {
      const response = await this._vm.$authApi.post(
        "/activation-link",
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  },
  async postActivateAccount({}, payload) {
    try {
      const response = await this._vm.$authApi.post(
        `/activate-account?token=${payload.token}`,
        {
          email: payload.email,
          password: payload.password,
        }
      );
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  },
  async getSubUser({}, payload){
    try {
      const response = await this._vm.$authApi.get(
        `/sub-user/partner/${payload}`
      );
      console.log("SUBUSEERR", response)

      return response.data;
    } catch (error) {
      return error;
    }
  },

  async getMaintenanceStatus({commit}){
    try{
      const response = await this._vm.$authApi.get(
        `/maintenance/status`
      );
      commit("SET_MAINTENANCE",response.data)
    }catch(err){

    }
  },

  async getSportsbookBetHistory({commit}, payload){
    console.log("payloooooooood", payload)
    try {
      const data = await this._vm.$authApi.get(
        `/user/bet/history/${payload.id}`, {
          params: {
            limit: payload.limit,
            page: payload.page
          },
        }
       
      );
      commit("SET_SPORTSBOOK_BET_HISTORY", data)
    } catch(err){
      console.log("error", err);
    }
  },
  
  async getMessage({}, payload){
    try{
      const data = await this._vm.$authApi.get(
        `/messages/user/${payload.id}`,{
          params: {
            search: payload.search,
            limit: payload.limit,
            page: payload.page,
          }
        }
      );
      return data.data;
    } catch(err){
      console.log("error", err)
    }
  },

  async getSentMessage({}, payload){
    try{
      const data = await this._vm.$authApi.get(
        `/messages/sent-items/${payload.id}`,{
          params: {
            search: payload.search,
            limit: payload.limit,
            page: payload.page,
          }
        }
      );
      return data.data;
    } catch(err){
      console.log("error",err)
    }
  },

  async createMessage({commit}, payload){
    try{
      const response = await this._vm.$authApi.post(
        "admin/messages/create",
        payload
      );
      const { data } = response;
      commit("SET_CREATE_MESSAGE", data)
    }catch(err){
      console.log("error",err);
    }
  },

  async updateMessage({ commit }, payload){
    try{
      const response = await this._vm.$authApi.post(
        `/messages/user/${payload.id}`,
        payload
      );
      const { data } = response;
      commit("SET_UPDATE_MESSAGE", data)
    }catch(err){
      console.log("error",err);
    }
  },
  async getUserIpAddress({}){
    try {
      const data = await this._vm.$authApi.get('/getIPAddress');
      return data.data;
    } catch (error) {
      console.log("error",error);
    }
  },
  async checkAffiliateVoucher({},payload){
    try {
      const data = await this._vm.$authApi.post('/validate-affiliate-voucher',payload);
      return data.data;
    } catch (error) {
      console.log("error",error);
    }
  },
  async socketAction({ commit }, payload){
    try{
      commit("setSocketMutation",payload)
    } catch(error){
      console.log("error",error)
    }
  }
};
export default actions;
