const actions = {
  async getUsers({commit}, payload) {
    try {
      const limit = payload && (payload.limit || 10);
      const search = payload && (payload.search || '');
      const page = payload && (payload.page || 1);
      const res = await this._vm.$gameApi.get('/admin/users', {
        params: {
          search,
          limit,
          page,
        },
      });
      commit("GET_USERS", res.data);
    } catch (error) {
      return error
    }
  },
  async getUserInfo({}, payload) {
    try {
      const res = await this._vm.$gameApi.get(`/user/account/info/${payload}`, payload);
      return res.data;
    } catch (error) {
      return error
    }
  },
  async banUser({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/users/ban/${payload.id}`, payload);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async unbanUser({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/users/unban/${payload}`, payload);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async setVoucherCode({}, payload) {
    try {
      return await this._vm.$gameApi.post(`/admin/users/voucher-code/${payload.id}`, payload);
    } catch (err) {}
  },
  async getUserTransactions({}, payload) {
    try {
      const limit = payload && (payload.limit || 10);
      const search = payload && (payload.search || '');
      const page = payload && (payload.page || 1);
      const res = await this._vm.$gameApi.get(`/admin/audit/users/${payload.type}/${payload.id}`, {
        params: {
          search,
          limit,
          page,
        },
      });
      return res.data;
    } catch (error) {
      return error
    }
  },
  async getUserBets({}, payload) {
    console.log("commmmmmmmmm", payload)
    try {
      const res = await this._vm.$gameApi.get(`/admin/audit/users/bets/${payload.id}`, {
        params:{
          page: payload.page,
          limit: payload.limit,
          search: payload.search,
        }
      });
      return res.data;
    } catch (error) {
      return error
    }
  },
  async addGiftCode({}, payload) {
    try {

      const res = await this._vm.$gameApi.post('/admin/add/gift-code', payload);

      return res.data;
    } catch (error) {
      return error
    }
  },
  async getAdminPartnerDepositTransaction({commit},payload){
    try {

      if (payload) {
        const response = await this._vm.$gameApi.get(
          'admin/partners/transactions',{
            params:{
              page: payload.page,
              limit: payload.limit,
              search: payload.search,
              id: payload.id,
              type: "deposit",
              status : payload.status
            }
          }
        );
        const { data } = response;

        commit("GET_ADMIN_PARTNER_DEPOSIT_TRANSACTIONS", data); 
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async getAdminPartnerCashoutTransaction({commit},payload){
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get(
          'admin/partners/transactions',{
            params:{
              page: payload.page,
              limit: payload.limit,
              search: payload.search,
              id: payload.id,
              type: "cashout",
              status : payload.status
            }
          }
        );
        const { data } = response;
        commit("GET_ADMIN_PARTNER_CASHOUT_TRANSACTIONS", data); 
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async getAdminUsersDepositTransaction({commit},payload){
    try {
      console.log("deposit search",payload)
      if (payload) {
        const response = await this._vm.$gameApi.get(
          'admin/users/transactions',{
            params:{
              page: payload.page,
              limit: payload.limit,
              search: payload.search,
              id: payload.id,
              type: "deposit",
              status: payload.status
            }
          }
        );
        const { data } = response;

        commit("GET_ADMIN_USERS_DEPOSIT_TRANSACTIONS", data); 
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async getAdminUsersCashoutTransaction({commit},payload){
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get(
          'admin/users/transactions',{
            params:{
              page: payload.page,
              limit: payload.limit,
              search: payload.search,
              id: payload.id,
              type: "cashout",
              status: payload.status
            }
          }
        );
        const { data } = response;
        commit("GET_ADMIN_USERS_CASHOUT_TRANSACTIONS", data); 
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async approveRequest({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/transactions/approve/${payload.id}`, payload);
      return res.data;
    } catch (error) {
      return error
    }
  },
  async getAllGiftCodes({}, payload) {
    try {
      const limit = payload && (payload.limit || 10);
      const search = payload && (payload.search || '');
      const page = payload && (payload.page || 1);
      const status = payload && (payload.status || "");
      const res = await this._vm.$gameApi.get('/admin/gift-code', {
        params: {
          search,
          limit,
          page,
          status
        },
      });
      if (!!search) {
        return {
          count : res.data.length,
          rows : res.data
        }
      }else{
        return res.data;
      }
    } catch (error) {
      return error
    }
  },
  async deleteGiftCode({}, payload) {
    try {
      const res = await this._vm.$gameApi.post('/admin/delete/gift-code', payload);
      return res.data;
    } catch (error) {
      return error
    }
  },
  async rejectRequest({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/transactions/reject/${payload.id}`, payload);
      return res.data;
    } catch (error) {
      return error
    }
  },
  async getTransactionDiscrepancies({commit},payload){
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get(
          `/admin/users/transaction-discrepancies/${payload.id}`,{
            params:{
              page: payload.page,
              limit: payload.limit,
              search: payload.search,
              id: payload.id,
            }
          }
        );
        const { data } = response;
        commit("GET_TRANSACTION_DISCREPANCIES", data); 
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async approveDiscrepancy({}, payload) {
    try {
      console.log("axasdcsac",payload);
      const res = await this._vm.$gameApi.post(`/admin/transactions/discrepancy/${payload.id}`, {
        amount : payload.amount,
        mop : JSON.stringify(payload.mop),
        message : payload.message
      });
      return res.data;
    } catch (error) {
      return error
    }
  },
  async updateDiscrepancy({}, payload) {
    try {
      console.log("axasdcsac",payload);
      const res = await this._vm.$gameApi.post(`/admin/transactions/update-discrepancy/${payload.id}`, {
        amount : payload.amount,
        mop : JSON.stringify(payload.mop),
        message : payload.message
      });
      return res.data;
    } catch (error) {
      return error
    }
  },
  async addSiteSettings({}, payload) {
    try {
      const res = await this._vm.$gameApi.post('/admin/add/site-settings', payload);
      return res.data;
    } catch (error) {
      return error
    }
  },
  async updateSiteSettings({}, payload) {
    try {
      const res = await this._vm.$gameApi.post('/admin/update/site-settings', payload);
      return res.data;
    } catch (error) {
      return error
    }
  },
  async updateSiteSettingsById({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/update/site-settings/${payload.id}`, payload);
      return res.data;
    } catch (error) {
      return error
    }
  },
  async getSiteSettings({commit}, payload) {
    try {
      const limit = payload && (payload.limit || 10);
      const search = payload && (payload.search || '');
      const page = payload && (payload.page || 1);
      const status = payload.status;
      const res = await this._vm.$gameApi.get('/user/banks/site-settings', {
        params: {
          search,
          limit,
          page,
          status
        },
      });
      commit("SET_SITE_SETTINGS", res.data);
    } catch (error) {
      return error
    }
  },
  async assignAsPartner({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/assign/partner/${payload.user_id}`, payload);
      return res.data;
    } catch (error) {
      return error
    }
  },
  async assignAsAdmin({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/users/${payload.id}`, payload);
      return res.data
    } catch (err) {}
  },
  async deactiveAsPartner({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/update/partner/${payload.id}`, {
        active : payload.status
      });
      return res.data
    } catch (err) {}
  },
  async activeAsPartner({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/update/partner/${payload.id}`, {
        active : payload.status,
        partner_name : payload.partner_name
      });
      return res.data
    } catch (err) {}
  },
  async createConfig({}, payload) {
    try {
      const res = await this._vm.$gameApi.post('/admin/config', payload);
      return res.data;
    } catch (error) {
      return error
    }
  },
  async getConfig({}, payload) {
    try {
      const search = payload && (payload.search || '');
      const res = await this._vm.$gameApi.get('/admin/config', {
        params: {
          search,
        },
      });
      return res.data;
    } catch (error) {
      return error
    }
  },
  async updateConfig({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/config/${payload.id}`, payload);
      return res.data;
    } catch (error) {
      return error
    }
  },
  async getAllBadges({commit}, payload) {
    try {
      const res = await this._vm.$gameApi.get('/admin/badges/', payload);
      commit("GET_BADGES", res.data);
    } catch (error) {
      return error
    }
  },
  async createBadge({}, payload) {
    try {
      const res = await this._vm.$gameApi.post('/admin/badges/', payload);
      return res.data;
    } catch (error) {
      return error
    }
  },
  async updateBadge({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/update/badge/${payload.id}`, payload);
      return res.data;
    } catch (error) {
      return error
    }
  },
  async addUserBadge({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/insert/user-badges/${payload.id}`, payload);
      return res.data;
    } catch (error) {
      return error
    }
  },
  async updateUser({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/users/${payload.id}`, payload);
      return res.data
    } catch (err) {}
  },
  async rejectPartnerRequest({}, payload) {
    try {

      const res = await this._vm.$gameApi.post(`/admin/partner-transactions/reject/${payload.id}`, payload);
      return res.data;
    } catch (error) {
      return error
    }
  },
  async approvePartnerRequest({}, payload) {
    try {

      const res = await this._vm.$gameApi.post(`/admin/partner-transactions/approve/${payload.id}`, payload);
      return res.data;
    } catch (error) {
      return error
    }
  },
  async adminPartnerList({commit},payload){
    try {
      if (payload) {
        const response = await this._vm.$gameApi.post(`/admin/partners?page=${payload.page}&limit=${payload.limit}&search=${payload.search}&status=${payload.status}&type=${payload.type}`,{
            date : payload.date
          }
        );
        const { data } = response;
        commit("GET_ADMIN_PARTNER", data); 
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async getPartnerAffiliates({commit},payload){
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get(`/admin/partners/affiliates/${payload.id}`);
        const { data } = response;
        commit("GET_PARTNER_AFFILIATES", data); 
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async getPartnerSubusers({commit},payload){
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get(`/admin/partners/sub-users/${payload.id}`);
        const { data } = response;
        commit("GET_PARTNER_SUBUSERS", data); 
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },

  async getPartnerSubPartners({commit},payload){
    try { 
      if (payload) {
        const response = await this._vm.$gameApi.get(`/admin/partners/sub-partners/${payload.id}`,{
          params:{
            type : 'default',
            search: !!payload.search ? payload.search : '',
          }
        });
        const { data } = response;
        commit("GET_PARTNER_SUBPARTNERS", data); 
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async postPartnerAffiliates({},payload){
    try {
      if (payload) {
        const response = await this._vm.$gameApi.post(`/admin/partners/add-affiliates/${payload.id}`,{
          ids: payload.ids
        });
      console.log("error", response);

        const { data } = response;
        return data
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async postPartnerSubusers({},payload){
    try {
      console.log("error", payload);

      if (payload) {
        const response = await this._vm.$gameApi.post(`/admin/partners/add-subusers/${payload.id}`,{
          ids: payload.ids,
          type: payload.type,
        });

        const { data } = response;
        return data
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async postPartnerSubPartners({},payload){
    try {
      if (payload) {
        const response = await this._vm.$gameApi.post(`/admin/partners/add-subagents/${payload.id}`,{
          ids: payload.ids
        });
      console.log("error", response);

        const { data } = response;
        return data
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async getAdminPartnerAdminDepositTransaction({commit},payload){
    try {

      if (payload) {
        const response = await this._vm.$gameApi.get(
          'admin/partner-transactions',{
            params:{
              page: payload.page,
              limit: payload.limit,
              search: payload.search,
              type: "deposit",
              status : payload.status
            }
          }
        );
        const { data } = response;

        commit("GET_ADMIN_PARTNER_ADMIN_DEPOSIT_TRANSACTIONS", data); 
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async getAdminPartnerAdminCashoutTransaction({commit},payload){
    try {

      if (payload) {
        const response = await this._vm.$gameApi.get(
          'admin/partner-transactions',{
            params:{
              page: payload.page,
              limit: payload.limit,
              search: payload.search,
              type: "cashout",
              status : payload.status
            }
          }
        );
        const { data } = response;

        commit("GET_ADMIN_PARTNER_ADMIN_CASHOUT_TRANSACTIONS", data); 
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async postDeactivePartner({},payload){
    try {
      if (payload) {
        const response = await this._vm.$gameApi.post(`/admin/update/partner/${payload.id}`,payload);

        const { data } = response;

        return data
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async postVisiblePartner({},payload){
    try {
      if (payload) {
        const response = await this._vm.$gameApi.post(`/admin/update/partner/${payload.id}`,payload);

        const { data } = response;

        return data
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async postAllowedUserPrevious({}, payload){
    try {
      if (payload) {
        const response = await this._vm.$gameApi.post(`/admin/update/partner/${payload.id}`,payload);

        const { data } = response;

        return data
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async getDownloadTransaction({},payload){
    try {
      if (payload) {
        const response = await this._vm.$gameApi.post(`/admin/partner-transactions/download/${payload.id}`,payload);

        const { data } = response;

        return data
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async getAuditUser({},payload){
    try {
      const response = await this._vm.$gameApi.post(`/admin/audit/users/formula/${payload.id}`);
      const { data } = response;
      return data;
    } catch (error) {
      return error
    }
  },
  async getAllVoucherCodes({}, payload) {
    try {
      const limit = payload && (payload.limit || 10);
      const search = payload && (payload.search || '');
      const page = payload && (payload.page || 1);
      const status = payload && (payload.status || "");
      const res = await this._vm.$gameApi.get('/admin/e-voucher-code', {
        params: {
          search,
          limit,
          page,
          status
        },
      });
      if (!!search) {
        return {
          count : res.data.length,
          rows : res.data
        }
      }else{
        return res.data;
      }
    } catch (error) {
      return error
    }
  },
  async addVoucherCode({}, payload) {
    try {
      console.log('payload voucher',payload)
      const res = await this._vm.$gameApi.post('/admin/add/e-voucher-code', payload);
      return res.data;
    } catch (error) {
      return error
    }
  },
  async deleteVoucherCode({}, payload) {
    try {
      const res = await this._vm.$gameApi.post('/admin/delete/e-voucher-code', payload);
      return res.data;
    } catch (error) {
      return error
    }
  },
  async updateToPaidEVoucher({ }, payload = {}) {
    try {
      const res = await this._vm.$gameApi.post("/admin/update-to-paid/e-voucher-code", {
        id:payload.id
      })  
      if (res.data.success) {
        return res.data
      }
    } catch (error) {
      return {
        success: false,
        message: error
      }
    }
    return {
      success: false,
    }
  },
  async getEvoucherTransaction({}, payload) {
    try {
      const limit = payload && (payload.limit || 10);
      const search = payload && (payload.search || '');
      const page = payload && (payload.page || 1);
      const res = await this._vm.$gameApi.get('/admin/users/e-voucher-code', {
        params: {
          search,
          limit,
          page,
        },
      });
      return res.data;
    } catch (error) {
      return error
    }
  },
  async getEvoucherAuditTransaction({}, payload){
    try {
      const limit = payload && (payload.limit || 10);
      const search = payload && (payload.search || '');
      const page = payload && (payload.page || 1);
      const res = await this._vm.$gameApi.get(`/user/voucher-code/deposit/${payload.id}`, {
        params: {
          search,
          limit,
          page,
        },
      });
      return res.data;
    } catch (error) {
      return error
    }
  },
  async getPartnerPayout({ commit }, payload){
    try{
      const res = await this._vm.$gameApi.post(`/admin/partners/payout-data/${payload.id}`,
        {
          date: payload.date
        }
      );
      const { data } = res;
      commit("GET_PARTNER_PAYOUT_ADMIN", data);
    } catch(error){
      console.log("error", error);
      return error;
    }
  },
  async generatePartnerPayout({ commit } , payload){
    try{
      const res = await this._vm.$gameApi.post(`/admin/partner/add/payout`,{
        payload
      })
      const { data } = res;
      commit("SET_GENERATE_PARTNER_PAYOUT", data)
    } catch(error){
      console.log("error", error);
      return error;
    }
  },
  async adminAffiliateList({commit}, payload){
    try {
      const res = await this._vm.$gameApi.post(`/admin/affiliates?page=${payload.page}&limit=${payload.limit}&search=${payload.search}&sort=${payload.sort}`,{
        date : payload.date,
      })
      const {data} = res;
      commit("GET_ADMIN_AFFILIATE",data)
    } catch (error) {
      console.log("error", error);
      return error;
    }
  },
  async getAffiliatePayoutData({commit}, payload){
    try{
      const res = await this._vm.$gameApi.post(`/admin/affiliates/payout-data/${payload.id}`,
        {
          date : payload.date
        }
      );
      const { data } = res;
      commit("GET_AFFILIATE_PAYOUT_DATA", data);
    } catch(error){
      console.log("error", error);
      return error;
    }
  },
  async generateAffiliatePayout({ commit } ,payload){
    try{
      console.log("PAYLOAD",payload)
      const res = await this._vm.$gameApi.post(`/admin/affiliate/add/payout`,
        payload
      )
      const { data } = res;
      commit("SET_GENERATE_AFFILIATE_PAYOUT", data)
    } catch(error){
      console.log("error", error);
      return error;
    }
  },
  async getAllPartnerPayout({ commit }, payload){
    try{
      const res = await this._vm.$gameApi.get('admin/partners/payouts',{
        params: {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          type: payload.type
        }
      })
      const { data } = res;
      commit("SET_ALL_PARTNER_PAYOUT", data);
    } catch(error){
      console.log("error",error);
      return error;
    }
  },
  async getPartnersReportList({},payload){
    try {
      const res = await this._vm.$gameApi.post(`admin/partners/sub-partners-tree/${payload.id}`,payload)
      return res.data.data
    } catch (error) {
      console.log("error",error);
      return error;
    }
  },
  async generatePartnerPayoutBulk({commit},payload){
    try{
      const res = await this._vm.$gameApi.post(`/admin/check/payouts-bulk?payout=false&type=payout`,
        {
          date: payload.date
        }
      );
      const { data } = res;
      console.log("xxxxxxxxxxx dataaaaaaaa", data)
      commit("GET_GENEREATE_PAYOUT_BULK_ADMIN", data);
    } catch(error){
      console.log("error", error);
      return error;
    }
  },
  async getPartnerPayoutBulk({commit}, payload){
    try{
      const res = await this._vm.$gameApi.post(`/admin/create/payouts-bulk`,
        {
          date: payload.date,
          data: payload.data
        }
      );
      const { data } = res;
      commit("GET_PARTNER_PAYOUT_BULK_ADMIN", data);
    } catch (error){
      console.log("error",error)
      return error;
    }
  },
  async getDownloadExcelPayoutBulk({commit}, payload){
    try{
      console.log("payloadddd jamesssssssssssssss", payload)
      const res = await this._vm.$gameApi.post(`/admin/generate/excel-payouts?type=${payload.type}`,{
          date: payload.date
        })
      console.log("reymonddddddddddddddddddddddddddddddddddddd",res)
      const { data } = res;
      return data;
      // commit("GET_DOWNLOAD_EXCEL_PAYOUT_BULK_ADMIN", data)
    } catch(error){
      console.log("error", error);
      return error;
    }
  },
  async getAffiliatePayout({commit},payload){
    try {
      const res = await this._vm.$gameApi.get('admin/affiliates/payouts',{
        params: {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
        }
      })
      const { data } = res;
      commit("GET_AFFILIATE_PAYOUT", data);
    } catch (error) {
      console.log("error", error);
      return error;
    }
  },
  async getMarketingUsers({commit},payload){
    try {
      const res = await this._vm.$gameApi.get('admin/marketing-users',{
        params: {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
        }
      })
      const { data } = res;
      commit("GET_MARKETING_USERS", data);
    } catch (error) {
      console.log("error", error);
      return error;
    }
  },
  async removeMarketingUsers({},payload){
    try {
      const res = await this._vm.$gameApi.post(`/admin/remove/marketing-user/${payload}`)
      const { data } = res;
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  },
  async checkPartnerPayout({},payload){
    try {
      const res = await this._vm.$gameApi.post(`/admin/partner/payout/checker/${payload.partner_id}`,{
        date : payload.date,
        user_id : payload.user_id
      })
      const {data} = res;
      return data;
    } catch (error) {
      return error
    }
  },
  async getPartnerSheets({commit},payload){
    try {

      const res = await this._vm.$gameApi.post(`/admin/partnersheet?search=${payload.search}&page=${payload.page}&limit=${payload.limit}`)
      const {data} = res;

      commit("GET_GOOGLE_SHEETS", data);
      return data;

    } catch (error) {
      console.log("error", error);
      return error;
    }
  },
  async createPartnerSheet({}, payload){
    try {
      const res = await this._vm.$gameApi.post('/admin/partnersheet/add-partnersheet',payload)
      const { data } = res;
      return data;
      // const res = await this._vm.$gameApi.post('/admin/add/e-voucher-code', payload);
      // return res.data;
    } catch (error) {
      return error
    }
  },
  async createPartnerVoucher({},payload){
    try {
      const res = await this._vm.$gameApi.post('/admin/partnersheet/add-partner-voucher',payload)
      const { data } = res;
      return data;
    } catch (error) {
      return error
    }
  },
  async adminAffiliateCommissionList({commit}, payload){
    try {
      const res = await this._vm.$gameApi.post('/admin/affiliates/commissions',{
        date : payload.date
      })
      const {data} = res;

      commit("GET_AFFILIATE_COMMISSIONS_LIST", data);
    } catch (error) {
      return error
    }
  },
  async findMarketingCredits({}, payload){
    try {
      const res = await this._vm.$gameApi.post('/admin/marketing-users/find-marketing-credits',{
        marketing_credits : payload
      })
      const {data} = res;
      return data
    } catch (error) {
      return error
    }
  },
  async checkMarketingCreditsClaimStatus({}, payload){
    try {
      const res = await this._vm.$gameApi.post('/admin/marketing-users/check-claim-status',{
        user_id : payload
      })
      console.log("FIND MC CODE",res)
      const {data} = res;
      return data
    } catch (error) {
      return error
    }
  },
};
export default actions;