import axios from "axios";
import Vue from "vue";

const gameApi = axios.create({
  baseURL: process.env.VUE_APP_GAME_API,
  withCredentials: true,
});

gameApi.interceptors.request.use(
  async function (config) {

    if (Vue.$cookies.isKey("_token")) {
      config.headers["Authorization"] = "Bearer " + Vue.$cookies.get("_token");
    }
    return config;
  },
  function (error) {
    return Promise.reject(error.response);
  }
);

gameApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default gameApi;
