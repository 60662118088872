<template>
  <v-dialog v-model="login_dialog" width="450" overlay-color="rgb(0, 0, 0)" overlay-opacity=".85" transition="slide-x-transition" style="z-index: 4000">
    <div>
      <v-card style="background: linear-gradient(140.61deg, #0b1638 0%, #39351a 100%)" class="rounded-lg overflow-x-hidden overflow-y-hidden py-5">
        <v-row class="mt-5">
          <v-col class="d-flex justify-center">
            <div>
              <v-img width="300" :src="require('@/assets/images/betgame-gg.svg')"></v-img>
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-7">
          <v-col cols="5" class="d-flex justify-center">
            <a @click="signup">
              <div class="font-weight-bold" style="color: gray">
                Sign Up
              </div>
            </a>
          </v-col>
          <v-col cols="5" class="d-flex justify-center">
            <div class="font-weight-bold px-2" style="border-bottom: 2px solid #d28945; color: #d28945">
              Login
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-5">
          <v-col cols="10" class="pb-0">
            <div v-if="error" class="text-caption text-start mb-1" style="color: red">
              {{ error_message }}
            </div>
            <v-text-field v-model="email" @keyup.enter="loginUser" hide-details solo background-color="rgba(69, 137, 210, 0.75)" class="mb-2 rounded-lg" placeholder="Enter Email"></v-text-field>
            <v-text-field v-model="password" @keyup.enter="loginUser" type="password" hide-details solo background-color="rgba(69, 137, 210, 0.75)" class="mb-2 rounded-lg" placeholder="Enter Password"></v-text-field>
            <v-row class="d-flex justify-center mb-2">
              <v-col cols="6" class="pr-0">
                <div class="text-start text-caption text-decoration-underline">
                  <a @click="forgotPassword">Forgot Password?</a>
                </div>
              </v-col>
              <v-col cols="6" class="d-flex justify-end pl-0">
                <v-checkbox color="#A1922B" hide-details class="mt-0 pt-0" style="transform: scale(0.8)">
                  <template v-slot:label>
                    <div>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a v-on="on">Remember Me</a>
                        </template>
                      </v-tooltip>
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-btn @click="loginUser" block rounded large color="#D28945" class="mt-5">login</v-btn>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center pt-1">
          <v-col cols="10">
            <!-- <v-row class="d-flex justify-center align-center">
              <v-col cols="5" class="pr-0">
                <div style="border: 1px solid gray"></div>
              </v-col>
              <v-col cols="2" class="pa-0">
                <div class="font-weight-bold">OR</div>
              </v-col>
              <v-col cols="5" class="pl-0">
                <div style="border: 1px solid gray"></div>
              </v-col>
            </v-row> -->
          </v-col>
        </v-row>
        <!-- <v-row class="d-flex justify-center mb-5">
          <v-col cols="10" class="pt-1">
            <v-facebook-login v-if="fbAppId" v-model="facebook.model" class="fbBtn" :app-id="fbAppId" :login-options="fbScope" :options="fbOptions" @connect="handleConnect" @sdk-init="handleSdkInit" @click="handleClick" @login="loggedInFb" @logout="handleLogout">
              <span slot="login">Continue with facebook </span>
              <span slot="logout">Logout with facebook </span>
            </v-facebook-login>
          </v-col>
        </v-row> -->
      </v-card>
    </div>
    <ForgotPassword :forgot_password_dialog="forgot_password_dialog" @closeForgotPasswordDialog="closeForgotPasswordDialog" />
    <ActivateAccount :activate_account_dialog="activate_account_dialog" @closeActivateAccountDialog="closeActivateAccountDialog" />
  </v-dialog>
</template>
<script>
// import VFacebookLogin from "vue-facebook-login-component";
import { mapActions, mapMutations, mapState } from "vuex";
import { publicIpv4 } from 'public-ip';
import ForgotPassword from "../auth/ForgotPassword.vue";
import ActivateAccount from "../auth/ActivateAccount.vue";
export default {
  components: {
    // VFacebookLogin,
    ForgotPassword,
    ActivateAccount,
  },
  data() {
    return {
      login_dialog: false,
      error: false,
      error_message: "",
      email: "",
      password: "",
      voucher_code: "",
      partner_link: "",
      // fbAppId: process.env.VUE_APP_FACEBOOK_APP_ID,
      // facebook: {
      //   model: {},
      //   auth: {},
      //   connected: false,
      // },
      // fbScope: {
      //   scope: "email, public_profile",
      // },
      // fbOptions: {
      //   status: true,
      // },
      // fbLoading: false,
      // FB: {},
      // model: {},
      // scope: {},
      // fbData: null,
      forgot_password_dialog: false,
      activate_account_dialog: false,
      ip_address: "",
    };
  },
  computed: {
    ...mapState("slotegator", ["show_login"]),
  },
  watch: {
    show_login() {
      this.login_dialog = this.show_login;
      this.setLinks();
    },
    login_dialog() {
      this.SET_SHOW_LOGIN(this.login_dialog);
      this.setLinks();
    },
  },
  async mounted() {
    this.setLinks();
    let ip_result = await publicIpv4({
      fallbackUrls: [
        'https://ifconfig.co/ip'
      ]
    });
    this.ip_address = ip_result;
  },
  methods: {
    ...mapActions("user", ["login", "fbLogin","getUserIpAddress"]),
    ...mapMutations("slotegator", ["SET_SHOW_LOGIN", "SET_SHOW_REGISTER"]),
    ...mapMutations("user", ["isLoading", "resetError"]),
    setLinks(){
      if (this.$route.query.voucher != "") {
      this.voucher_code = this.$route.query.voucher;
      }
      if (this.$route.query.partner) {
        this.partner_link = this.$route.query.partner;
      }
    },  
    signup() {
      this.SET_SHOW_LOGIN(false);
      this.SET_SHOW_REGISTER(true);
    },
    async loginUser() {
      if (/.+@.+\..+/.test(this.email)) {
        let data = {
          email: this.email,
          password: this.password,
          ip_address: this.ip_address,
        };
        let res = await this.login(data);
        if (res.data.success) {
          this.error = false;
          this.error_message = "";
          this.email = "";
          this.password = "";
          this.$emit("loginSuccess", true);
          console.log("this auth", this.$auth.user);
          if (this.$auth.user.type == "admin") {
            window.location.href = "/admin";
            // this.$router.push('/admin').catch(()=>{});
          }
          else if(this.$auth.user.type == "super-admin"){
            window.location.href = "/admin";
          }
          else if(this.$cookies.get("is_partner")){
            window.location.href = "/partner/dashboard";
          }
          // else if(this.$auth.user.voucher_code != null && this.$auth.user.type == 'user'){
          //     this.$router.push('/partner').catch(()=>{});
          // }
          else {
            window.location.href = "/";
            //    this.$router.push('/').catch(()=>{});
          }
        } else {
          this.error = true;
          this.error_message = "Invalid email or password";
          this.password = "";
          if (res.data.status == "inactive") {
            this.activateAccount();
          }
        }
      } else {
        this.error = true;
        this.error_message = "Email is not valid";
      }
    },
    // handleClick() {
    //   this.fbLoading = true;
    // },
    // handleSdkInit({ FB, scope }) {
    //   this.FB = FB;
    //   this.scope = scope;
    // },
    // async handleConnect() {
    //   this.facebook.auth = {
    //     ...this.facebook.auth,
    //     voucher_code: this.voucher_code || null,
    //     partner_link: this.partner_link || null,
    //     ip_address: this.ip_address,
    //   };
    //   if (this.facebook.auth.status == "connected") {
    //     let res = await this.fbLogin(this.facebook.auth);
    //     if (res.success) {
    //       this.error = false;
    //       this.error_message = "";
    //       this.$emit("loginSuccess", true);
    //       this.handleLogout();
    //       window.location.href = "/";
    //     } else {
    //       this.error = true;
    //       if (res.message == "User is banned") {
    //         this.error_message = res.message + " until " + res.bannedUntil;
    //       } else {
    //         this.error_message = `Voucher code not found`;
    //       }
    //     }
    //   } else {
    //     this.error = true;
    //     this.error_message = "";
    //   }
    // },
    // loggedInFb(payload) {
    //   this.facebook.auth = payload;
    // },
    handleLogout: function (payload) {
      this.scope.logout();
      this.facebook.auth = payload;
    },
    forgotPassword() {
      this.forgot_password_dialog = true;
      this.SET_SHOW_LOGIN(false);
    },
    activateAccount() {
      this.activate_account_dialog = true;
      this.SET_SHOW_LOGIN(false);
    },
    closeForgotPasswordDialog() {
      this.forgot_password_dialog = false;
    },
    closeActivateAccountDialog() {
      this.activate_account_dialog = false;
    },
    getIP(json){
      console.log("JSON IP",json)
    }
  },
};
</script>
<style>
.mdi-checkbox-blank-outline {
  border: none;
  size: 300px !important;
  color: #d28945 !important;
}
.fbBtn {
  width: 100%;
  border-radius: 20px !important;
  cursor: pointer !important;
}
</style>