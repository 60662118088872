<template>
  <v-dialog
    v-model="dialog"
    width="450"
    overlay-color="rgb(0, 0, 0)"
    overlay-opacity=".85"
    transition="slide-x-transition"
    style="z-index: 4000"
    @click:outside="closeActivateAccountDialog"
    @keydown.esc="closeActivateAccountDialog"
  >
    <div>
      <!-- <v-card
        style="background: linear-gradient(140.61deg, #0b1638 0%, #39351a 100%)"
        class="rounded-lg overflow-x-hidden overflow-y-hidden py-5"
      >
        <v-row class="mt-5">
          <v-col class="d-flex justify-center">
            <div>
              <v-img
                width="300"
                :src="require('@/assets/images/betgame-gg.svg')"
              ></v-img>
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-7">
          <v-col cols="5" class="d-flex justify-center">
            <div
              class="font-weight-bold px-2"
              style="border-bottom: 2px solid #d28945; color: #d28945"
            >
              Reactivate Account
            </div>
          </v-col>
        </v-row>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row class="d-flex justify-center my-5 mx-5">
            <v-col cols="12" class="">
              <div class="font-weight-black" style="color: #ff4e4e">
                DEACTIVATED ACCOUNT 
              </div>
            </v-col>
            <v-col class="">
              <div class="text-caption">
                User account is inactive, send request to reactivate your account
              </div>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="email"
                solo
                background-color="rgba(69, 137, 210, 0.75)"
                class="mb-2 rounded-lg"
                placeholder="Enter Email"
                required
                :rules="emailRules"
              ></v-text-field>
              <v-btn
                block
                rounded
                large
                color="#D28945"
                class="mt-5"
                :loading="loading"
                @click="sendLink"
                >Send Reactivate Request</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card> -->
       <v-card
        style="background: linear-gradient(140.61deg, #0b1638 0%, #39351a 100%)"
        class="rounded-lg overflow-x-hidden overflow-y-hidden py-5"
      >
        <v-row class="mt-5">
          <v-col class="d-flex justify-center">
            <div>
              <v-img
                width="300"
                :src="require('@/assets/images/betgame-gg.svg')"
              ></v-img>
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-7">
          <v-col cols="5" class="d-flex justify-center">
            <div
              class="font-weight-bold px-2"
              style="border-bottom: 2px solid #d28945; color: #d28945"
            >
              WARNING
            </div>
          </v-col>
        </v-row>
          <v-row class="d-flex justify-center my-5 mx-5">
            <v-col cols="12" class="">
              <div class="font-weight-black" style="color: #ff4e4e">
                <v-alert
                    color="error"
                  >Your account has been suspended. Please contact Betgame Customer Service here.</v-alert>
              </div>
            </v-col>  
            <v-col class="">
              <div class="text-caption">
                If you think this is a mistake. Please contact our <a href="https://www.facebook.com/betgame.gg">support</a> in our facebook page.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn small color="success" @click="dialog = false">Okay</v-btn>   
            </v-col>
          </v-row>
      </v-card>
      <Alert
        :alert_dialog="alert_dialog"
        :alert_success="alert_success"
        :alert_message="alert_message"
        @closeAlertDialog="closeAlertDialog"
      />
    </div>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import Alert from "../alert/Alert.vue";
export default {
  props: ["activate_account_dialog"],
  data() {
    return {
      dialog: false,
      email: "",
      alert_dialog: false,
      alert_success: "",
      alert_message: "",
      loading: false,
      valid: true,
      emailRules: [
        (email) => !!email || "E-mail is required",
        (email) => /.+@.+/.test(email) || "E-mail must be valid",
      ],
    };
  },
  components: {
    Alert,
  },
  watch: {
    activate_account_dialog(value) {
      this.dialog = value;
    },
  },
  methods: {
    ...mapActions("user", ["sendActivationLink"]),

    closeActivateAccountDialog() {
      this.$emit("closeActivateAccountDialog", true);
    },
    async sendLink() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.loading = true;
        const res = await this.sendActivationLink({
          email: this.email,
        });
        if (res.success) {
          this.loading = false;
          this.alert_dialog = true;
          this.alert_success = res.success;
          this.alert_message = "Linked Send Successfully";
        } else {
          this.alert_dialog = true;
          this.alert_message = res.message;
        }
        console.log("ressss", res);
      }
    },
    closeAlertDialog() {
      this.alert_dialog = false;
      this.closeActivateAccountDialog();
    },
  },
};
</script>
<style>
.mdi-checkbox-blank-outline {
  border: none;
  size: 300px !important;
  color: #d28945 !important;
}
.fbBtn {
  width: 100%;
  border-radius: 20px !important;
  cursor: pointer !important;
}
</style>