<template>
	<v-container class="deposit">
			<v-form ref="form" v-model="valid" lazy-validation>
				<v-row>
					<v-col class="pl-8">
						<v-col class="px-0">
							<v-card class="transfer-deposit" dark>
								<v-card-title class="transfer-deposit-text d-flex justify-center"
								>My Balance</v-card-title
								>
								<v-card-text class="transfer-deposit-text"
								>₱{{
									partnerprofile && partnerprofile.partner_credits | formatMoney
								}}</v-card-text
								>
							</v-card>
						</v-col>
						<v-col class="px-0">
							<v-card class="transfer-deposit" dark>
									<v-card-title class="transfer-deposit-text d-flex justify-center"
								>My Total-Available</v-card-title
								>
								<v-card-text class="transfer-deposit-text"
								>₱{{ totalBalance | formatMoney }}</v-card-text
								>
							</v-card>
						</v-col>
					</v-col>
					<v-col class="pr-8">
						<v-col class="px-0">
							<v-card class="transfer-deposit" dark>
								<v-card-title class="transfer-deposit-text d-flex justify-center"
								>User Balance</v-card-title
								>
								<v-card-text class="transfer-deposit-text">&#8369;{{
									formatNumber(userBalance)
								}}</v-card-text>
							</v-card>
						</v-col>
					</v-col>
				</v-row>
				<v-row class="px-5">
					<v-col>
						<v-autocomplete
						v-model="selectPartnerUser"
						clearable
						outlined
						:items="agentList"
						label="Agents"
						item-value="id"
						item-text="name"
						:rules="partnerUserRules"
						required
						rounded
						hide-details
						class="transfer-deposit-input"
						>
							<template v-slot:item="data">
								<template>
									<div>
										<v-chip small :color="getColor(data.item.type)">{{getInitial(data.item.type)}}</v-chip>  {{data.item.name}}
									</div>
								</template>
							</template>
						</v-autocomplete>
					</v-col>
					<v-col>
						<v-text-field
						:rules="amountRules"
						label="Amount"
						v-model="amount"
						outlined
						required
						rounded
						hide-details
						class="transfer-deposit-input"
						></v-text-field>
					</v-col>
					<v-col class="pt-0" cols="12">
						<v-textarea
						v-model="comment"
						outlined
						name="input-7-4"
						label="Comment"
						class="transfer-deposit-input"
						hide-details
						></v-textarea>
					</v-col>
				</v-row>
				<v-card-actions class="transfer-deposit-text d-flex justify-center py-10">
					<v-btn color="white" text @click="closeDialog"> CANCEL </v-btn>
					<v-btn color="#45D0D2" rounded @click="openConfirmation">
						TRANSFER
					</v-btn>
				</v-card-actions>
				<v-dialog
				v-model="confirmDialog"
				@click:outside="closeDialog"
				@keydown.esc="closeDialog"
				height="400"
				width="400"
				>
					<v-card class="card">
						<v-col cols="12" class="pt-10 d-flex justify-center">
							<v-img max-height="75" max-width="75" :src="image"></v-img>
						</v-col>
						<v-col cols="12" class="d-flex justify-center pb-0">
							<v-card-title class="message-header font-weight-bold">
								ARE YOU SURE?
							</v-card-title>
						</v-col>
						<v-col cols="12" class="d-flex justify-center pt-0">
							<v-card-title class="message-header text-caption pt-0">
								<span class="text-wrap" style="width: 100%">Are you sure to</span>
								<span class="text-wrap" style="width: 100%"
								>proceed the Transaction?</span
								>
							</v-card-title>
						</v-col>
						<v-col cols="12" class="d-flex justify-center">
							<v-card-actions class="pb-10">
								<v-spacer></v-spacer>
								<v-btn
								class="dialog-btn"
								color="success"
								rounded
								@click="transferDeposit"
								>
								YES
								</v-btn>
								<v-btn
								class="dialog-btn"
								color="error"
								rounded
								@click="closeconfirmDialog"
								>
								NO
								</v-btn>
							</v-card-actions>
						</v-col>
					</v-card>
				</v-dialog>
			</v-form>
			<Alert
			:alert_dialog="alert_dialog"
			:alert_success="alert_success"
			:alert_message="alert_message"
			@closeAlertDialog="closeAlertDialog"
			/>
	</v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Alert from "../../../dashboard/modal/Alert.vue";
import numeral from "numeral" 
import Question from "@/assets/images/alert/question.svg";
export default {
	props:["viewDialog"],
	data() {
		return {
			items: ["Foo", "Bar", "Fizz", "Buzz"],
			selectPartnerUser: "",
			selectedUser: "",
			agentDetails: [],
			amount: "",
			alert_dialog: false,
			alert_success: "",
			alert_message: "",
			valid: true,
			comment: "",
			amountRules: [
			(amount) => !!amount || "amount is required",
			(amount) => (amount && amount >= 100) || "Minimum of 100",
			],
			partnerUserRules: [
			(selectPartnerUser) =>
			!!selectPartnerUser || "Transaction type is required",
			],
			commentRules: [(comment) => !!comment || "Transaction type is required"],
			confirmDialog: false,
			image: Question,
			partnerprofile : {},
		};
		
	},
	components: {
		Alert,
	},
	computed: {
		...mapState("partner", [
		"partneruser",
		"agenttransfertransaction",
		]),
		...mapState("user", ["auth_user"]),
		...mapState("admin", ["adminpartnersubpartners"]),
		agentList() {
			
			console.log("PARTNER USER",this.partnerprofile)
			if (this.partnerprofile.type != 'partners' && this.partnerprofile.type != 'city-manager' && this.partnerprofile.type != 'area-manager') {
				console.log("TEST DATA")
				let subagent = this.adminpartnersubpartners && this.adminpartnersubpartners.currentSubPartners.map(data => {
					let res = this.adminpartnersubpartners.subPartners.find(subs => subs.id == data.id)
					return {
						id : res && res.id,
						name: res && res.partner_name.charAt(0).toUpperCase() + res.partner_name.slice(1),
						credits: res && res.partner_credits,
						type: res && res.type
					}
				});
				return subagent;
			}else{
				console.log("TEST DATA 1")
				let subagent = this.adminpartnersubpartners && this.adminpartnersubpartners.map(data => {
					return {
						id : data && data.id,
						name: data && data.partner_name.charAt(0).toUpperCase() + data.partner_name.slice(1),
						credits: data && data.partner_credits,
						type: data && data.type
					}
				});
				return subagent;
			}
		},
		totalBalance() {
			let partner_credits =
			this.partnerprofile && this.partnerprofile.partner_credits;
			let total = partner_credits;
			return total;
		},
		userBalance() {
			let balance = this.selectedUser
			? this.selectedUser.credits | this.formatMoney
			: "-";
			return balance;
		},
	},
	methods: {
		...mapActions("partner", [
		"partnerUsers",
		"getPartnerProfileData",
		"postAgentTransferTransaction",
		]),
		...mapActions("user", ["getUserInfo"]),
		...mapActions("admin", ["getPartnerSubPartners"]),
        ...mapActions("user", ["getSubUser"]),
		closeDialog() {
			this.$emit("closeDialog", true);
		},
		closeconfirmDialog(){
			this.confirmDialog = false;
		},
		async transferDeposit() {
			this.$refs.form.validate();
			if (this.$refs.form.validate()) {
				const res = await this.getSubUser(this.$auth.user.id)
				await this.postAgentTransferTransaction({
					agent_id: this.selectPartnerUser,
					type: "deposit",
					amount: this.amount,
					partner_id: res.id,
					status: "completed",
					transaction_type: "direct",
					partner_comment : this.comment
				});
				if (this.agenttransfertransaction.updated) {
					this.closeDialog();
					const res = await this.getSubUser(this.$auth.user.id)
					await this.getPartnerProfileData({
						id: res.user_id,
					});
					await this.getUserInfo(res.user_id);
					await this.getPartnerSubPartners({id : res.id});
					this.$refs.form.reset();
				}
				this.alert_dialog = true;
				this.alert_success = this.agenttransfertransaction.updated;
				this.alert_message = !this.alert_success ? this.agenttransfertransaction.message : "TRANSFER CREDITS SUCCESSFULLY";
			}
		},
		closeAlertDialog() {
				this.alert_dialog = false;
		},
		formatNumber(value){
			return numeral(value).format('0,0.00')
		},
		getInitial(type){
			console.log("type initial", type)
			if(type == 'partners')
			return 'P';
			else if(type == 'area-manager')
			return 'AM';
			else if (type == "city-manager")
			return 'CM';
			else{
				return 'A';
			}
		},
		getColor(type){
			console.log("color", type)
			if(type == 'partners')
			return 'success';
			else if(type == 'area-manager')
			return 'warning';
			else if(type == "city-manager")
			return 'primary';
			else{
				return 'error';
			}
		},
		openConfirmation(){
			this.$refs.form.validate();
			if (this.$refs.form.validate()) {
				this.confirmDialog = true
			}
		}
	},
	async mounted() {
        const res = await this.getSubUser(this.$auth.user.id)
        await this.getPartnerSubPartners({id : res.id});
        await this.getPartnerProfileData({ id: res.user_id });
        await this.getUserInfo(res.user_id);
	},
	watch: {
		async selectPartnerUser(value) {
			let user =
			this.agentList &&
			this.agentList.find((data) => data.id == value);
			console.log("PARTNER",user)

			this.selectedUser = user;
		},
		async viewDialog(){
            const res = await this.getSubUser(this.$auth.user.id);
            await this.getPartnerSubPartners({id : res.id});
            await this.getPartnerProfileData({ id: res.user_id });
            await this.getUserInfo(res.user_id);
		}
	},
	async created(){
		this.partnerprofile = await this.getSubUser(this.$auth.user.id);
	}
};
</script>
<style>
.deposit {
	background: #0d111b !important;
}
.transfer-deposit-input {
	background: #222f3a !important;
}
.transfer-deposit {
	background: #222f3a !important;
	border-radius: 10px !important;
}
.transfer-deposit-text {
	font-family: "Inter", sans-serif !important;
	font-style: normal !important;
	font-weight: 700 !important;
	font-size: 14px !important;
	line-height: 17px !important;
	/* identical to box height */
	
	text-align: center !important;
	
	color: #ffffff !important;
}
</style>
<style scoped>
.card {
	border-radius: 25px !important;
	background-color: #0d111b !important;
}
.dialog-btn {
	width: 100px;
	height: 36px;
}
</style>