import Vue from "vue";
import jwtDecode from "jwt-decode";
export default async (to, from, next) => {
  const hasToken = Vue.$cookies.isKey("_token");
  if (hasToken) {
    const token = Vue.$cookies.get("_token");
    const decodedToken = jwtDecode(token);
    if ((decodedToken.user.type == "user" || decodedToken.user.type == "admin" ) && to.meta.super_admin) {
      return next("/");
    } else {
      return next();
    }
  }
  if (to.meta.super_admin) {
    return next("/");
  }
  next();
};
