const mutations = {
    SET_USER: (state, payload) => {
        state.user = payload;
    },
    GET_USER_EARNINGS: (state, payload) => {
        state.earnings = payload;
    },
    SET_USER_DATA: (state, payload) => {
        state.auth_user = payload;
    },
    GET_REGISTERED_USER: (state, payload) => {
        state.registered_user = payload;
    },
    SET_MAINTENANCE: (state, payload) => {
        state.maintenance_type = payload.maintenance;
        state.allowed_users = payload.allowed_users;
        state.allowed_pp_users = payload.allowed_pp_users
    },
    login(state, user) {
        state.user = user;
    },
    logout(state) {
        state.user = null;
    },
    setError: (state, payload) => {
        state.error = payload;
      },
    loginFailed(state, error) {
        state.error = error;
    },
    stopLoading(state) {
        state.loading = false;
    },
    resetError(state) {
        state.error = false;
    },
    isLoading(state) {
        state.loading = true;
    },
    getSubUser(state,payload){
        state.subuser= payload
    },
    SET_SPORTSBOOK_BET_HISTORY: (state,payload) => {
        state.sportsbookbethistory = payload;
    },
    // SET_MESSAGE_EMAIL: (state,payload) => {
    //     state.messageemail = payload;
    // },
    SET_CREATE_MESSAGE: (state,payload) => {
        state.createmessage = payload;
    },
    setSocketMutation: (state,payload) => {
        state.socket = payload;
    },
    SET_UPDATE_MESSAGE: (state, payload) => {
        state.updatemessage = payload;
    }
    // PUSH_MESSAGE: (state,payload) => {
    //     state.messageemail.data.rows = state.messageemail.data.rows.push(payload);
    // }

};
  
export default mutations;