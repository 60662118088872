const mutations = {
    SET_USER: (state, payload) => {
        state.user = payload;
    },
    GET_USER_EARNINGS: (state, payload) => {
        state.earnings = payload;
    },
    SET_USER_DATA: (state, payload) => {
        state.auth_user = payload;
    },

    SET_BT_READER: (state, payload) => {
        state.bt_reader = payload;
    },
    SET_SHOW_LOGIN: (state, payload) => {
        state.show_login = payload;
    },
    SET_SHOW_REGISTER: (state, payload) => {
        state.show_register = payload;
    },
    SET_SHOW_DEPOSIT: (state, payload) => {
        state.show_deposit = payload;
    },
    SET_SHOW_PARTNER_DEPOSIT: (state, payload) => {
        state.show_partner_deposit = payload;
    },
    SET_SESSION_REFRESH: (state, payload) => {
        state.session_refresh = payload;
    }

};

export default mutations;