const state = {
    user: null,
    earnings: null,
    auth_user: null,
    bt_reader: null,
    show_register: false,
    show_login: false,
    session_refresh: null,
    show_deposit: false,
    show_partner_deposit: false
};

export default state;
