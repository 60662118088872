<template>
  <v-dialog v-model="viewDialog" width="700" @click:outside="closeDialog"  @keydown.esc="closeDialog">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card  class="add-user">
        <v-card-title class="text-h6 d-flex justify-center py-10">
          Add Agent
        </v-card-title>

        <v-card-actions class="pa-3" v-if="1 == 2">
          <v-row>
            <!-- NAME -->
            <v-col cols="12" lg="6" md="6" class="px-7">
              <v-text-field
                label="Name"
                outlined
                hide-details
                v-model="name"
                dense
                :rules="nameRules"
                required
                class="add-user-input"
              ></v-text-field>
              <div class="tw-text-left">
                <span :class="`tw-text-xs ${check_name.success ? 'tw-text-green-500' : 'tw-text-red-500'}`">
                  {{check_name.message}}
                </span>
              </div>
            </v-col>
            <!-- EMAIL -->
            <v-col cols="12" lg="6" md="6" class="px-7">
              <v-text-field
                label="Email"
                outlined
                hide-details
                type="email"
                v-model="email"
                dense
                :rules="emailRules"
                required
                class="add-user-input"
              ></v-text-field>
              <div class="tw-text-left">
                <span :class="`tw-text-xs ${check_email.success ? 'tw-text-green-500' : 'tw-text-red-500'}`">
                  {{check_email.message}}
                </span>
              </div>
            </v-col>
            <!-- PASSWORD -->
            <v-col cols="12" lg="6" md="6" class="px-7">
              <v-text-field
                label="Password"
                outlined
                hide-details
                v-model="password"
                dense
                :rules="passwordRules"
                required 
                type="password"
                class="add-user-input"
              ></v-text-field>
            </v-col>
            <!-- CONFIRM PASSWORD -->
            <v-col cols="12" lg="6" md="6" class="px-7">
              <v-text-field
                label="Confirm Passowrd"
                outlined
                hide-details
                dense
                v-model="password_confirmation"
                :rules="password_confirmationRules"
                required
                type="password"
                class="add-user-input"
              ></v-text-field>
            </v-col>
            <!-- PARENT -->
            <v-col cols="12" lg="6" md="6" class="px-7">
              <!-- <v-select
                :items="partnerList"
                item-text="partner_name"
                :return-object="true"
                item-value="id"
                label="Agent"
                outlined
                dense
                hide-details
                v-model="partner"
                :rules="partnerRules"
                required
                class="add-user-input"
              ></v-select> -->
              <v-text-field
                outlined
                dense
                hide-details
                v-model="partner"
                disabled
                class="add-user-input"
              ></v-text-field>
            </v-col>
            <!-- ROLE -->
            <v-col cols="12" lg="6" md="6" class="px-7">
              <v-select
                :items="agentRolesList"
                label="Roles"
                outlined
                dense
                hide-details
                v-model="type"
                :rules="typeRules"
                required
                class="add-user-input "
                style="text-align: left;"
                item-text="id"
                item-value="name"
              >
                <template slot="item" slot-scope="data">
                    {{ data.item }}
                </template>
              </v-select>
            </v-col>
            <!-- PARTNER NAME -->
            <v-col cols="12" lg="6" md="6" class="px-7">
              <v-text-field
                label="Partner Name"
                outlined
                hide-details
                v-model="partner_name"
                dense
                :rules="partnerNameRules"
                required
                class="add-user-input"
              ></v-text-field>
              <div class="tw-text-left">
                <span :class="`tw-text-xs ${check_partner_name.success ? 'tw-text-green-500' : 'tw-text-red-500'}`">
                  {{check_partner_name.message}}
                </span>
              </div>
            </v-col>
            <!-- COMMISSION PERCENTAGE -->
            <v-col cols="12" lg="6" md="6" class="px-7">
              <v-text-field
                label="Commission Percentage"
                outlined
                hide-details
                v-model="commission_percentage"
                dense
                :rules="commissionPercentageRules"
                required
                class="add-user-input"
                type="number"
              ></v-text-field>
            </v-col>
             
            
          </v-row>
        </v-card-actions>
        <v-card-actions class="pa-3 display-inline" v-else>
          <div class="tw-text-center text-center">
            <span class="">
              <v-icon class="tw-text-red-500 color-yellow"> mdi-information </v-icon>&nbsp; Agent creation is currently unavailable. Please contact support.
            </span>
          </div>
        </v-card-actions>

        <v-card-actions class="d-flex justify-center py-10">
          <v-btn color="white" text @click="closeDialog"> CANCEL </v-btn>
          <v-btn v-if="1 == 2" color="#45D0D2" rounded :disabled="isDisabled"  @click="registerUser"> REGISTER </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <Alert
      :alert_dialog="alert_dialog"
      :alert_success="alert_success"
      :alert_message="alert_message"
      @closeAlertDialog="closeAlertDialog"
    />
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { useDebounceFn } from "@vueuse/core";
import Alert from "../modal/Alert.vue";
export default {
  props: ["addAgentDialog"],
  data() {
    return {
        items: ["Foo", "Bar", "Fizz", "Buzz"],
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        partner: "",
        alert_dialog: false,
        alert_success: "",
        alert_message: "",
        valid: true,
        nameRules: [(name) => !!name || "Name is required"],
        emailRules: [
            (email) => !!email || "E-mail is required",
            (email) => /.+@.+/.test(email) || "E-mail must be valid",
        ],
        passwordRules: [
            (password) => !!password || "Password is required",
        ],
        password_confirmationRules: [
            (password_confirmation) =>
            !!password_confirmation || "Password Confirmation is required",
        ],
        partnerRules: [(partner) => !!partner || "Name is required"],
        agent_role_list: [],
        agent_role: "",
        agentRoleRules: [(agent_role) => !!agent_role || "Roles is required"],
        partner_name: "",
        partnerNameRules: [(partner_name) => !!partner_name || "Partner Name is required"],
        commission_percentage: "",
        commissionPercentageRules: [(commission_percentage) => !!commission_percentage || "Commission Percentage is required"],
        type: "",
        typeRules: [(type) => !!type || "Type is required"],
        debouncedName: useDebounceFn(async () => {
          await this.checkNameResult()
        }, 1000),
        debouncedEmail: useDebounceFn(async () => {
          await this.checkEmailResult()
        }, 1000),
        debouncedPartnerName: useDebounceFn(async () => {
          await this.checkPartnerNameResult()
        }, 1000),
        check_name : "",
        check_email : "",
        check_partner_name : "",
        isDisabled: false,
        ip_address: ""
    };
  },
  components: {
    Alert,
  },
  computed: {
    ...mapState("partner", ["partnerprofile", "adduser"]),
    viewDialog() {
      return this.addAgentDialog;
    },
    partnerList() {
      const partner = [
        {
          partner_name: this.partnerprofile && this.partnerprofile.partner_name,
          id: this.partnerprofile && this.partnerprofile.id,
        },
      ];
      return partner;
    },
    agentRolesList(){
        if (this.partnerprofile.type == "partners") {
            return this.agent_role_list = ["City-manager","Area-manager","Agent"]
        }else if(this.partnerprofile.type == "city-manager"){
            return this.agent_role_list = ["Area-manager","Agent"]
        }else if(this.partnerprofile.type == "area-manager"){
            return this.agent_role_list = ["Agent"]
        }
    }
  },
  methods: {
    ...mapActions("partner", ["getPartnerProfileData", "postAddUser","checkUserInfo"]),
    ...mapActions("user", ["getUserIpAddress"]),
    closeDialog() {
        console.log("this.adfadfadfda", this.partner);
        this.$emit("closeDialog", true);
        this.name =  "";
        this.email =  "";
        this.password =  "";
        this.password_confirmation =  "";
        this.nameRules = false;
        this.emailRules = false;
        this.passwordRules = false;
        this.password_confirmationRules = false;
        this.check_name = "";
        this.check_email = "";
        this.partner_name = "";
        this.commission_percentage = "";
        this.partnerNameRules = false;
        this.commissionPercentageRules = false;
        this.typeRules = false;
        // this.check_partner_name.message = "";
        // this.check_email.message = "";
    },
    closeAlertDialog() {
      console.log("this.partnerprofile.partner_name;", this.partnerprofile.partner_name)
        this.isDisabled = false;
        this.alert_dialog = false;
        this.partner = this.partnerprofile.partner_name;
        this.check_partner_name.message = "";
        this.check_email.message = "";
       
    },
    async registerUser() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.isDisabled = true;
        await this.postAddUser({
            name: this.name,
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation,
            partner_id: this.partnerprofile.id,
            partner_name : this.partner_name,
            commission_percentage: this.commission_percentage,
            type:this.type,
            is_partner : true,
            ip_address : this.$auth.user.last_logged_in_ip
        });
        if (this.adduser.success) {
            this.closeDialog();
            this.$refs.form.reset();
            this.partner = this.partnerprofile.partner_name;
        }
        this.alert_dialog = true;
        this.alert_success = this.adduser.success;
        this.alert_message = !this.alert_success
          ? this.adduser.message
          : "NEW AGENT ADDED SUCCESSFULLY";
      }
    },
    async checkNameResult(){
      if(this.name && this.name.length > 4){
         const res = await this.checkUserInfo({
        name : this.name,
      });

      this.check_name = res.data && res.data
      this.isDisabled = !this.check_name.success;
      }else{
        this.check_name = {
          message: "The name must contain at least 5 characters"
        }
      }
    },
    async checkEmailResult(){
     if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)){
         const res = await this.checkUserInfo({
          email : this.email,
        });
        this.check_email = res.data && res.data
        this.isDisabled = !this.check_email.success;
        
      }else{
         this.check_email = {
          message: "Please enter a valid email address"
        }
      }
    },
    async checkPartnerNameResult(){
      const res = await this.checkUserInfo({
        partner_name : this.partner_name
      });
      this.check_partner_name = res.data && res.data
      this.isDisabled = !this.check_partner_name.success;
    }
  },
  async mounted() {
    this.partner = this.partnerprofile.partner_name;
  },
  watch:{
    name(){
      this.debouncedName()
    },
    email(){
      this.debouncedEmail()
    },
    partner_name(){
      this.debouncedPartnerName()
    },
    addAgentDialog(){
      this.partner = this.partnerprofile.partner_name;
      console.log('adf',this.partner)
    }
  }
};
</script>
<style>
.add-user{
  background: #0D111B !important;
  border-radius: 20px !important;
}.add-user-input{
  border-radius: 5px;
  border: 0;
  /* background: #222F3A; */
  text-align: left !important;
}
.v-select__selection {
    justify-content: left !important;
}

.display-inline {
  display: inline !important;
}

.text-center {
  text-align: center !important;
}

.color-yellow {
  color: yellow !important;
}
</style>