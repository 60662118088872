const actions = {
    async getDashboardTotalByDate({commit}, payload) {
        try{
            console.log('by date',payload)
            const res = await this._vm.$gameApi.post(`/affiliate/dashboard/${payload.id}`,{
              date : payload.date
            });
            const { data } = res;
            commit("GET_DASHBOARD_TOTAL_BY_DATE", data);
          } catch (error){
            console.log("error", error);
            commit("SET_ERROR", error);
          }
    },
    async getDashboardAffiliateGraph({ commit }, payload){
        try{
          console.log('byy win', payload)
          const response = await this._vm.$gameApi.post(`/affiliate/dashboard-graph/${payload.id}`,{
            date : payload.date
          });
          console.log("payload mbbb", response);
          const { data } = response;
          commit("GET_DASHBOARD_TOTAL_GRAPH_BY_DATE", data);
        } catch(error){
          console.log("error",error);
          commit("SET_ERROR", error);
        }
    },
    async getDashboardAffiliatePayoutHistory({ commit }, payload){
      console.log("xxxxx payload", payload)
      try {
        const response = await this._vm.$gameApi.get(`/affiliate/payouts/${payload.id}`,{
          params: {
            page: payload.page,
            limit: payload.limit
          }
        })
        console.log("payload qweeeeeeeeeeeee", response)
        const { data } = response;
        console.log("response;", data)
        commit("GET_DASHBOARD_PAYOUT_HISTORY", data);
      } catch(error){
        console.log("error",erorr);
        commit("SET_ERROR",error);
      }
    },
    async affiliateUsers({ commit }, payload) {
      try {
        const res = await this._vm.$gameApi.get(`/affiliate/tree/${payload.id}`,{
          params:{
            page: payload.page,
            limit: payload.limit,
            search: payload.search,
          }
        });
        const { data } = res;
        commit("GET_AFFILIATE_USER", data);
      } catch (error) {
        console.log("error", error);
        commit("SET_ERROR", error);
      }
    },
    async getUserBets({commit}, payload){
      try {
        const res = await this._vm.$gameApi.get(`/affiliate/users/bets/${payload.id}`,{
          params:{
            page: payload.page,
            limit: payload.limit,
            type: ""
          }
        })
        // const { data } = res;
        return res.data;
      } catch (error) {
        console.log("error", error);
        commit("SET_ERROR,", error);
      }
    },
    async getUserBetsSports({commit}, payload){
      try {
        const res = await this._vm.$gameApi.get(`/affiliate/users/bets/${payload.id}`,{
          params:{
            page: payload.page,
            limit: payload.limit,
            type: payload.type
          }
        })
        // const { data } = res;
        return res.data;
      } catch (error) {
        console.log("error", error);
        commit("SET_ERROR,", error);
      }
    },
    async getAffiliateDataCommission({commit}, payload){
      try {
        const response = await this._vm.$gameApi.post(`/affiliate/profile-commissions/${payload.id}`,{
            date : payload.date
        })
        const { data } = response;
        return data;
      } catch (error) {
        console.log("error", error);
      }
    }
};
export default actions;