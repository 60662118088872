const mutations = {
    SET_ERROR: (state, payload) => {
        state.error = payload;
    },
    SET_CASINO_GAMES: (state, payload) => {
        state.casinogames = payload;
    },
    SET_ADMIN_CASINO_GAMES_GET: (state, payload) => {
        state.admincasinogames = payload;
    },
    SET_ADMIN_CASINO_JILI_GAMES_GET: (state, payload) => {
        state.admincasinojiligames = payload;
    },
    SET_CASINO_GAMES_DETAILS: (state,payload) => {
        state.admincasinogamedetails = payload;
    },
    SET_ADMIN_CASINO_PRAGMATIC_GAMES_GET: (state,payload) => {
        state.admincasinopragmaticgames = payload
    },
    SET_ADMIN_CASINO_ONE_API_GAMES_GET: (state,payload) => {
        state.admincasinooneapigames = payload
    }
}
export default mutations;