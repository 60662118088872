<template>
  <v-app dark class="gradient tw-relative">
    <!-- <CustomerChat/> -->
    <div class="tw-min-h-[100vh] tw-flex tw-flex-col">
      <Header />
      <main class="pt-0">
        <slot></slot>
      </main>
    </div>
    <Footer />
  </v-app>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
// import CustomerChat from "../components/auth/CustomerChat.vue"
export default {
  components: {
    Header,
    Footer,
    // CustomerChat
  },
  data: () => ({
    links: ["Dashboard", "Messages", "Profile", "Updates"],
  })
};
</script>
<style scoped>
.gradient {
  background: #0b132b;
}
</style>