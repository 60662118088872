
const actions = {
    async init({ }, payload) {
        try {
            console.log('payload', payload)
            const data = await this._vm.$gameApi.post("/sportsbooks/init", payload);
            console.log("daaaaataa", data)
            return data;
        } catch (er) {
            return er;
        }
    },

    async postAuthGuest({ }, payload) {
        try {
            const data = await this._vm.$gameApi.post("/postauth-guest", payload);
            console.log("postAuthGuest", data)
            return data;
        } catch (er) {
            return er;
        }
    },

    async selfValidate({ }, payload) {
        try {
            const data = await this._vm.$gameApi.post("/self-validate/run", payload);
            console.log("selfValidate", data)
            return data;
        } catch (er) {
            return er;
        }
    },

    async sportsbooks({ }) {
        try {
            const data = await this._vm.$gameApi.get("/sportsbooks");
            console.log("daaaaataa", data)
            return data.data;
        } catch (er) {
            return er;
        }
    },


};
export default actions;
