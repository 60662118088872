const state = {
  user: null,
  earnings: null,
  auth_user: null,
  registered_user: null,
  error: false,
  loading: false,
  loadingText: 'Sending request...',
  subuser: null,
  maintenance_type: null,
  allowed_users:[],
  sportsbookbethistory: null,
  // messageemail: null,
  createmessage: null,
  socket: null,
  updatemessage: null
};

export default state;
