import jwtDecode from "jwt-decode";
export default {
  install(Vue, store) {
    Vue.mixin({
      computed: {
        token() {
          return Vue.$cookies.get("_token");
        },
        $auth() {
          try {
            const token = this.token;
            const decoded = token && (jwtDecode(token) || null);
            let auth = {
              user: decoded.user,
            };
          
            if (auth.user) {
              auth.logout = async () => {
                try {
                  await store.dispatch("user/logout");
                } catch (err) {}
                Vue.$cookies.remove("_token");
                return true;
              };
            }
            return auth;
          } catch (er) {}
          return {
            user: null,
          };
        },
      },
    });
  },
};