<template>
  <v-dialog
    overlay-color="#000000"
    overlay-opacity="0.5"
    v-model="viewDialog"
    persistent
    max-width="553"
  >
    <v-card class="alert-card pa-10">
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <v-img max-height="75" max-width="75" :src="alertImage"></v-img>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <v-card-title  class="message-header">
            {{ alertMessageHeader }}
          </v-card-title>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <v-card-title class="text-h6 font-weight-bold">
            <p class="tw-text-[#45d28e]">{{ cashoutData && cashoutData.code }}</p>
          </v-card-title>
        </v-col>
        <v-col v-if="!status" cols="12" class="d-flex justify-center">
          <v-card-title class="text-caption">
            {{ !status ? alertMessageBody.toUpperCase() : "" }}
          </v-card-title>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="dialog-btn"
              color="warning"
              rounded
              @click="closeDialog"
            >
              {{ status == true ? "CONTINUE" : "OK" }}
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: [
    "dialog",
    "alertMessageBody",
    "alertImage",
    "alertMessageHeader",
    "status",
    "cashoutData"
  ],
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    viewDialog() {
      return this.dialog;
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog", true);
      // this.$router.push({path: '/profile', name: 'profile', params: { titles: "Transaction History" }}).catch(()=>{});
    },
  },
  mounted() {},
};
</script>
<style scoped>
.alert-card {
  border-radius: 25px !important;
  background-color: #0d111b !important;
}
.message-body {
  font-style: normal;
  font-weight: 750;
  font-size: 18px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;
  text-decoration-line: underline;
}
.message-header {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  word-break: break-word !important;
  width: 80% !important;
}
.dialog-btn {
  width: 200px;
  height: 36px;
}
</style>