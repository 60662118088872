const state = {
    sitesettings: null,
    directcashout: null,
    directdeposit: null,
    depositdirecttransactions: null,
    cashoutdirecttransactions: null,
    voucherdeposittransactions: null
  };
  
  export default state;
  