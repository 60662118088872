const mutations = {
    GET_SITE_SETTINGS: (state, payload) => {
        state.sitesettings = payload;
    },
    DIRECT_CASHOUT: (state, payload) => {
        state.directcashout = payload;
    },
    DIRECT_DEPOSIT: (state, payload) => {
        state.directdeposit = payload;
    },
    GET_DEPOSIT_DIRECT_TRANSACTIONS: (state, payload) => {
        state.depositdirecttransactions = payload;
      },
    GET_CASHOUT_DIRECT_TRANSACTIONS: (state, payload) => {
    state.cashoutdirecttransactions = payload;
    },
    GET_VOUCHER_DEPOSIT_TRANSACTIONS: (state, payload) => {
    state.voucherdeposittransactions = payload;
    },
    SET_ERROR: (state, payload) => {
        state.error = payload;
    },
};
  
export default mutations;