import Vue from "vue";
import vuetify from "@/plugins/vuetify";
import VueNumber from "vue-number-animation";
import VueCookies from "vue-cookies-reactive";
import device from "vue-device-detector"
import JsonExcel from "vue-json-excel";
import 'whatwg-fetch'
import VueApexCharts from "vue-apexcharts"
import DateRangePicker from 'vue2-daterange-picker'
import VMdDateRangePicker from "v-md-date-range-picker";
import VueClipboards from 'vue-clipboards';
import VueToast from 'vue-toast-notification';
import PortalVue from 'portal-vue'
import VueFetch from 'vue-fetch'
import { SpinnerPlugin } from 'bootstrap-vue'
import wysiwyg from "vue-wysiwyg";
import VueFbLogin from "vue-facebook-login-component"
import VuetifyMoney from "vuetify-money";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { authApi, gameApi } from "./api";
import axios from 'axios'
import userData from "./plugins/userData";

import "./assets/css/tailwind.css";
import "nprogress/nprogress.css";
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import './assets/js/googleanalytics.js'
import {
  formatMoney,
  formatFee,
  formatNum,
} from "./helpers/filters/formatNumber.js";

import infiniteScroll from 'vue-infinite-scroll'
Vue.use(PortalVue)
Vue.use(device)
Vue.config.productionTip = false;
Vue.use(VueNumber);
Vue.use(VueCookies);
Vue.use(VueApexCharts);
Vue.component("downloadExcel", JsonExcel);
Vue.component('apexchart', VueApexCharts)
Vue.use(DateRangePicker);
Vue.use(VMdDateRangePicker);
Vue.use(VueClipboards);
Vue.use(VueToast);
Vue.use(VueFetch, {
  polyfill: false,
  url: false   //should vue-fetch load promise polyfill, set to false to use customer polyfill
});
Vue.use(SpinnerPlugin)
Vue.use(infiniteScroll)
Vue.use(wysiwyg, {
  maxHeight: "20px"
})
Vue.component("v-facebook-login",VueFbLogin)
Vue.use(VuetifyMoney)

if(process.env.VUE_APP_NODE_ENV == 'production'){
  console.log  = ()=>{}
  console.error = ()=>{}
  console.warn = ()=>{}
}


Vue.filter("formatMoney", formatMoney);
Vue.filter("formatFee", formatFee);
Vue.filter("formatNum", formatNum);


Vue.prototype.$authApi = authApi;
Vue.prototype.$gameApi = gameApi;
Vue.prototype.$axios = axios;

Vue.prototype.$btnColor = '#D28945';
Vue.prototype.$projGreen = '#45D28E';
Vue.prototype.$projBlue = '#4589D2';
Vue.prototype.$projCyan = '#45CFD2';

Vue.use(userData, store);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
