<template>
  <v-dialog overlay-color="#000000" overlay-opacity="0.5" v-model="viewDialog" persistent max-width="1200" max-height="100%" class="tw-z-[10000]" @click:outside="closeDirectDialog"  @keydown.esc="closeDirectDialog">
    <v-card class="alert-card pa-10">
      <v-card-title>DIRECT E-VOUCHER</v-card-title>
    <v-container class="container">
      <v-form v-if="showDepositForm" ref="form" v-model="valid" lazy-validation>
        <v-row class="pt-10">
          <v-col
            :class="$vuetify.breakpoint.mdAndDown && 'pa-10'"
            :cols="$vuetify.breakpoint.mdAndDown && '12'"
          >
            <v-col
              :class="$vuetify.breakpoint.lgAndUp && 'text-select'"
              align="left"
              cols="12"
            >
              <h6>
                Amount to “{{
                  transactionType ? transactionType : "Deposit/Cashout"
                }}”
              </h6>
            </v-col>
            <v-col :class="$vuetify.breakpoint.lgAndUp && 'select'" cols="12">
              <v-text-field
                :rules="amountRules"
                v-model="amount"
                type="number"
                required
                light
                label="Minimum of 100"
                dense
                solo
              ></v-text-field>
            </v-col>
            <v-col
              :class="$vuetify.breakpoint.lgAndUp && 'text-select'"
              align="left"
              cols="12"
            >
              <h6>E-Voucher Code</h6>
            </v-col>
            <v-col :class="$vuetify.breakpoint.lgAndUp && 'select'" cols="12">
              <v-text-field
                :rules="voucherRules"
                v-model="voucher"
                required
                light
                label="Voucher"
                dense
                solo
              ></v-text-field>
            </v-col>
          </v-col>
          <v-col
            :class="$vuetify.breakpoint.mdAndDown && 'px-10'"
            :cols="
              $vuetify.breakpoint.mdAndDown
                ? `${showInfo ? '12' : '12'}`
                : `${showInfo ? '3' : '3'}`
            "
            :align="$vuetify.breakpoint.mdAndDown && 'center'"
          >
          </v-col>
        </v-row>
        <v-row>
          <v-col
            :align="$vuetify.breakpoint.mdAndDown && 'right'"
            :class="$vuetify.breakpoint.mdAndDown && 'px-10'"
          >
            <div>
              <v-btn
                class="deposit-btn al"
                @click="submitDepositRequest"
                color="warning"
                rounded
                dark
              >
                {{ transactionType ? transactionType : "Deposit/Cashout" }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <AlertSuccess
          :dialog="dialog"
          :alertMessageBody="alertMessageBody"
          :alertImage="alertImage"
          :alertMessageHeader="alertMessageHeader"
          :status="transactionStatus"
          @closeDialog="closeDialog"
        />
      </v-form>
    </v-container>
     </v-card>
  </v-dialog>
  </template>
  <script>
  import { mapState, mapActions } from "vuex";
  import AlertSuccess from "../Alert/Success.vue";
  import Success from "@/assets/images/alert/success.svg";
  import Failed from "@/assets/images/alert/failed.svg";
  export default {
     props: ["directDialog"],
    data() {
      return {
        valid: true,
        amount: "",
        transactionType: "Deposit/Cashout",
        selectBank: "",
        userTypeList: [],
        transactionCode: "XX-XXXXXXXXXXXX",
        facebookLink: "",
        message_info: "* Copy and Paste the Transaction Code",
        showInfo: false,
        alertMessageBody: "",
        alertMessageHeader: "",
        dialog: false,
        alertImage: "",
        transactionStatus: "",
        amountRules: [
          (amount) => !!amount || "amount is required",
          (amount) => (amount && amount >= 100) || "Minimum of 100",
        ],
        showDepositForm: true,
        checkCreditsAmount: "",
        continueSummary: false,
        checkCreditsAvailable: "",
        checkCashoutDetails: false,
        voucher: "",
        voucherRules: [
          (voucher) => !!voucher || "Voucher is required",
        ],
        viewDialog: false,

      };
    },
    components: {
      AlertSuccess,
    },
    computed: {
      ...mapState("direct", [
        "directdeposit",
        "depositdirecttransactions",
      ]),
      ...mapState("user", ["auth_user"]),
      ...mapState("slotegator", ["show_deposit"]),
    },
    methods: {
      ...mapActions("direct", [
        "directDeposit",
        "getDirectDepositTransactions",
      ]),
      //DEPOSIT REQUEST
      async submitDepositRequest() {
        this.$refs.form.validate();
        if (this.$refs.form.validate()) {
          let data = {
            mop: this.selectBank.type,
            account_name: this.selectBank.account_name,
            account_number: this.selectBank.account_number,
          };
          await this.directDeposit({
            amount: parseInt(this.amount),
            code: this.voucher,
            user_id: this.$auth.user.id,
          });
          await this.getDepositTransaction();
          if (this.directdeposit) {
            this.transactionStatus = this.directdeposit.success;
            if (this.directdeposit.success == true) {
              this.alertMessageHeader =
                "DEPOSIT TRANSACTION REQUEST WAS SUCCESSFUL ";
              this.alertImage = Success;
            } else {
                this.showInfo = false;
                this.alertImage = Failed;
                this.alertMessageHeader =
                "DEPOSIT TRANSACTION REQUEST WAS UNSUCCESSFUL ";
                this.alertMessageBody = this.directdeposit.message;
            }
            this.dialog = true;
            this.$refs.form.reset();
          }
        }
      },
      //END DEPOSIT REQUEST
     
      closeDialog() {
        this.dialog = false;
        if (this.directdeposit) {
          if (this.directdeposit.success == true) {
            this.showInfo = true;
            this.$refs.form.reset();
          } else {
            this.showInfo = false;
          }
        }
        this.closeDirectDialog();
      },
      async getDepositTransaction() {
        await this.getDirectDepositTransactions({
          page: 1,
          limit: 10,
          id: this.$auth.user.id,
          search: "",
        });
      },
      closeDirectDialog() {
      this.$emit("closeDirectDialog", true);
    },
    },
    async mounted() {
      this.viewDialog = this.directDialog;
    },
    watch: {
      amount(value) {
        let amount = value;
        let creditAvailable = Math.round(this.auth_user.credits);
        if (amount == 0 || amount == "") {
          this.checkCreditsAmount = "";
        } else {
          if (amount > creditAvailable && amount != 0 && amount != "") {
            this.checkCreditsAmount = "credit-failed";
            // this.checkCreditsAvailable = true;
            this.goBackBtnColor = "error";
          } else {
            this.checkCreditsAmount = "credit-success";
            // this.checkCreditsAvailable = false;
            this.goBackBtnColor = "default";
          }
        }
      },
      show_deposit() {
      console.log("heyyy", this.show_deposit);
      this.viewDialog = this.show_deposit;
    },
    },
  };
  </script>
  <style scoped>
  .container {
    background-color: transparent !important;
  }
  .select {
    padding-left: 300px;
  }
  .text-select {
    font-style: normal;
    font-weight: 750;
    font-size: 18px;
    line-height: 2px;
    padding-left: 250px;
  }
  .information {
    width: 255.82px;
    height: 355px;
    background: #26466c;
    border-radius: 10px;
    position: relative;
  }
  .information:before {
    content: "";
    position: absolute;
    top: 270px;
    left: -30px;
    z-index: 1;
    border: solid 15px transparent;
    border-right-color: #26466c;
  }
  .info-header {
    font-style: normal;
    font-weight: 750;
    font-size: 16px;
    line-height: 19px;
  }
  .info-transaction {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
  }
  .info-code {
    font-style: normal;
    font-weight: 750;
    font-size: 15px;
    line-height: 14px;
  }
  .info-message {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
  }
  .info-fb {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 10px;
    text-decoration-line: underline;
  }
  .info-footer {
    font-style: normal;
    font-weight: 750;
    font-size: 12px;
  }
  .deposit-btn {
    width: 219px;
    height: 45px;
  }
  .cashout-select {
    padding-left: 66px;
    padding-right: 66px;
  }
  .text-cashout {
    font-style: normal;
    font-weight: 750;
    font-size: 18px;
    line-height: 2px;
    padding-left: 70px;
  }
  .cashout-information {
    width: 90%;
    height: 200px;
    background: #26466c;
    border-radius: 10px;
  }
  .mobile-cashout-information {
    width: 90%;
    min-height: 200px;
    background: #26466c;
    border-radius: 10px;
  }
  .disclaimer {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  .cashout-summary-details {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding-left: 160px;
  }
  .credit-success {
    font-style: normal;
    font-weight: 700;
    color: #45d28e;
  }
  .credit-failed {
    font-style: normal;
    font-weight: 700;
    color: #d24545;
  }
  .cashout-btn {
    width: 45%;
  }
  .withdraw-btn {
    width: 219px;
    height: 45px;
  }
  .withdraw-btn.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background: rgba(210, 137, 69, 0.5) !important;
  }
  .input-cashout.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background: rgba(210, 137, 69, 0.5) !important;
  }
  .mobile-information {
    width: 255.82px;
    height: 355px;
    background: #26466c;
    border-radius: 10px;
    position: relative;
  }
  </style>