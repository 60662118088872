import axios from "axios";
import Vue from "vue";


const authApi = axios.create({
  baseURL: process.env.VUE_APP_AUTH_API,
  withCredentials: true,
});

authApi.interceptors.request.use(
  async function (config) {

  

    if (Vue.$cookies.isKey("_token")) {
      config.headers["Authorization"] = "Bearer " + Vue.$cookies.get("_token");
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

authApi.interceptors.response.use(
  function (response) {
    if (response.headers["x-token"]) {
      Vue.$cookies.set("_token", response.headers["x-token"]);
    }

    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default authApi;
