<template>
  <v-container class="cashout">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col class="pl-8">
          <v-col class="px-0">
            <v-card class="transfer-cashout" dark>
              <v-card-title class="transfer-cashout-text d-flex justify-center"
                >My Balance</v-card-title
              >
              <v-card-text class="transfer-cashout-text"
                >₱{{
                  partnerprofile && partnerprofile.partner_credits | formatMoney
                }}</v-card-text
              >
            </v-card>
          </v-col>
          <v-col class="px-0">
            <v-card class="transfer-cashout" dark>
              <v-card-title class="transfer-cashout-text d-flex justify-center"
                >My Total-Available</v-card-title
              >
              <v-card-text class="transfer-cashout-text"
                >₱{{ totalBalance | formatMoney }}</v-card-text
              >
            </v-card>
          </v-col>
        </v-col>
        <v-col class="pr-8">
          <v-col class="px-0">
            <v-card class="transfer-cashout" dark>
              <v-card-title class="transfer-cashout-text d-flex justify-center"
                >User Balance</v-card-title
              >
              <v-card-text class="transfer-cashout-text">&#8369;{{
                formatNumber(userBalance)
              }}</v-card-text>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="px-5">
        <v-col>
          <v-autocomplete
            v-model="selectPartnerUser"
            clearable
            outlined
            :items="partnerUserList"
            label="Users"
            item-value="id"
            item-text="name"
            :rules="partnerUserRules"
            required
            rounded
            hide-details
            class="transfer-cashout-input"
            :search-input.sync="search"
          >
            <template v-slot:item="data">
                  <template>
									<v-row>
                    <v-col class="px-0" cols="2"><v-chip x-small :color="getColor(data.item.type)" class="tw-text-xs">{{getInitial(data.item.type)}}</v-chip></v-col>
                    <v-col class="pr-0 tw-text-left">
                      <span class="tw-text-xs">
                        {{data.item.partner}} - {{data.item.name}}
                      </span>
                    </v-col>
									</v-row>
								</template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-text-field
            :rules="amountRules"
            label="Amount"
            v-model="amount"
            outlined
            required
            rounded
            hide-details
            class="transfer-cashout-input"
          ></v-text-field>
        </v-col>
        <v-col class="pt-0" cols="12">
          <v-textarea
            v-model="comment"
            outlined
            name="input-7-4"
            label="Comment"
            class="transfer-cashout-input"
            hide-details
          ></v-textarea>
        </v-col>
      </v-row>

      <v-card-actions class="transfer-deposit-text d-flex justify-center py-10">
        <v-btn color="white" text @click="closeDialog"> CANCEL </v-btn>
        <v-btn color="#45D0D2" rounded @click="openConfirmation">
          TRANSFER
        </v-btn>
      </v-card-actions>
      <v-dialog
      v-model="confirmDialog"
      @click:outside="closeDialog"
      @keydown.esc="closeDialog"
      height="400"
      width="400"
      >
        <v-card class="card">
          <v-col cols="12" class="pt-10 d-flex justify-center">
            <v-img max-height="75" max-width="75" :src="image"></v-img>
          </v-col>
          <v-col cols="12" class="d-flex justify-center pb-0">
            <v-card-title class="message-header font-weight-bold">
              ARE YOU SURE?
            </v-card-title>
          </v-col>
          <v-col cols="12" class="d-flex justify-center pt-0">
            <v-card-title class="message-header text-caption pt-0">
              <span class="text-wrap" style="width: 100%">Are you sure to</span>
              <span class="text-wrap" style="width: 100%"
              >proceed the Transaction?</span
              >
            </v-card-title>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-card-actions class="pb-10">
              <v-spacer></v-spacer>
              <v-btn
              class="dialog-btn"
              color="success"
              rounded
              @click="transferCashout"
              >
              YES
              </v-btn>
              <v-btn
              class="dialog-btn"
              color="error"
              rounded
              @click="closeconfirmDialog"
              >
              NO
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-card>
      </v-dialog>
    </v-form>
    <Alert
      :alert_dialog="alert_dialog"
      :alert_success="alert_success"
      :alert_message="alert_message"
      @closeAlertDialog="closeAlertDialog"
    />
  </v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Alert from "../../../dashboard/modal/Alert.vue";
import numeral from "numeral"
import Question from "@/assets/images/alert/question.svg";

export default {
  props:["viewDialog"],
  data() {
    return {
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      selectPartnerUser: "",
      selectedUser: "",
      agentDetails: [],
      amount: "",
      alert_dialog: false,
      alert_success: "",
      alert_message: "",
      valid: true,
      comment: "",
      amountRules: [
        (amount) => !!amount || "amount is required",
        (amount) => (amount && amount >= 500) || "Minimum of 500",
      ],
      partnerUserRules: [
        (selectPartnerUser) =>
          !!selectPartnerUser || "Transaction type is required",
      ],
      commentRules: [(comment) => !!comment || "Transaction type is required"],
      confirmDialog: false,
			image: Question,
      curPage: 1,
      default_item_per_page: 50,
      search: "",
      partnerprofile : {},
    };
  },
  components: {
    Alert,
  },
  computed: {
    ...mapState("partner", [
      "partneruser",
      "transfertransaction",
    ]),
    ...mapState("user", ["auth_user"]),
		...mapState("admin", ["adminpartnersubpartners"]),
    partnerUserList() {
      if (this.partnerprofile.type != 'partners') {
        let user =
          this.partneruser &&
          this.partneruser.rows.map((data) => {
            return {
              id: data.id,
              name: data.name.charAt(0).toUpperCase() + data.name.slice(1),
              partner :data.registeredPartner.partner_name.charAt(0).toUpperCase() + data.registeredPartner.partner_name.slice(1),
              type : data.registeredPartner.type
            };
          });
        return user;
      }else{
        let subagent = this.adminpartnersubpartners && this.adminpartnersubpartners.map(data => {
          return data.user_id;
        });
          let tempUsers = [] ;
          let register_user = this.adminpartnersubpartners && this.adminpartnersubpartners.map(data => {
            data.registeredUsers.map(user=>{
              let find_user = !subagent.includes(user.id.toString())
              if(find_user){
                tempUsers.push({
                  id: user.id,
                  name: user.name.charAt(0).toUpperCase() + user.name.slice(1),
                  partner : data.partner_name.charAt(0).toUpperCase() + data.partner_name.slice(1),
                  type : data.type
                })
              }
            })
            return tempUsers;
          });
          let users =
          this.partneruser &&
          this.partneruser.rows.map((data) => {
            return {
              id: data.id,
              name: data.name.charAt(0).toUpperCase() + data.name.slice(1),
              partner : data.registeredPartner.partner_name.charAt(0).toUpperCase() + data.registeredPartner.partner_name.slice(1),
              type : data.registeredPartner.type
            };
          });
          let tPlayer = users || [];
          let result = [...tPlayer,...tempUsers]
        

        return result;
      }
      
    },
    totalBalance() {
      let partner_credits =
        this.partnerprofile && this.partnerprofile.partner_credits;
      let total = partner_credits;
      return total;
    },
    userBalance() {
      let balance = this.selectedUser ? this.selectedUser.credits : null;
      return balance;
    },
    
  },
  methods: {
    ...mapActions("partner", [
      "partnerUsers",
      "getPartnerProfileData",
      "postTransferTransaction",
    ]),
    ...mapActions("user", ["getUserInfo"]),
		...mapActions("admin", ["getPartnerSubPartners"]),
    ...mapActions("user", ["getSubUser"]),
    closeDialog() {
      this.$emit("closeDialog", true);
    },
    closeconfirmDialog(){
			this.confirmDialog = false;
		},
    async transferCashout() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        const res = await this.getSubUser(this.$auth.user.id)
        await this.postTransferTransaction({
          user_id: this.selectPartnerUser,
          type: "cashout",
          amount: this.amount,
          partner_id: res.id,
          status: "completed",
          transaction_type: "direct",
        });
        if (this.transfertransaction.updated) {
          this.closeDialog();
          const res = await this.getSubUser(this.$auth.user.id)
          await this.getPartnerProfileData({
            id: res.user_id,
          });
          await this.getUserInfo(res.user_id);
					await this.getPartnerSubPartners({id : res.id});
          await this.searchData();
          this.$refs.form.reset();
        }
        this.alert_dialog = true;
        this.alert_success = this.transfertransaction.updated;
        this.alert_message = !this.alert_success ? this.transfertransaction.message : "TRANSFER CREDITS SUCCESSFULLY";
      }
    },
    closeAlertDialog() {
      this.alert_dialog = false;
    },
    formatNumber(value){
      return numeral(value).format('0,0.00')
    },
    openConfirmation(){
			this.$refs.form.validate();
			if (this.$refs.form.validate()) {
				this.confirmDialog = true
			}
		},
    async searchData(item) {
        const res = await this.getSubUser(this.$auth.user.id);
        await this.partnerUsers({
            page: this.curPage,
            limit: this.default_item_per_page,
            search: this.search || "",
            id: res.id,
        });
    },
    getInitial(type){
        if(type == 'partners')
            return 'P';
        else if(type == 'area-manager')
            return 'AM';
        else if (type == "city-manager")
            return 'CM';
        else{
            return 'A';
        }
    },
    getColor(type){
        if(type == 'partners')
            return 'success';
        else if(type == 'area-manager')
            return 'warning';
        else if(type == "city-manager")
            return 'primary';
        else{
            return 'error';
        }
    },
  },
  async mounted() {
    const res = await this.getSubUser(this.$auth.user.id);
    await this.searchData();
    await this.getPartnerProfileData({ id: res.user_id, });
    await this.getUserInfo(res.user_id,);
		await this.getPartnerSubPartners({id : res.id});
  },
  watch: {
    async selectPartnerUser(value) {
      if (this.partnerprofile.type != 'partners') {
      let user =
        this.partneruser &&
        this.partneruser.rows.find((data) => data.id == value);
      this.selectedUser = user;
      }else{
        let subagent = this.adminpartnersubpartners && this.adminpartnersubpartners.map(data => {
          return data.user_id;
        });
        let tempUsers = [] ;
          let register_user = this.adminpartnersubpartners && this.adminpartnersubpartners.map(data => {
            data.registeredUsers.map(user=>{
              let find_user = !subagent.includes(user.id.toString())
              if(find_user){
                tempUsers.push({
                  id: user.id,
                  name: user.name.charAt(0).toUpperCase() + user.name.slice(1),
                  credits: user.credits,
                })
              }
            })
            return tempUsers;
          });
          let users =
          this.partneruser &&
          this.partneruser.rows.map((data) => {
            return {
              id: data.id,
              name: data.name.charAt(0).toUpperCase() + data.name.slice(1),
              credits: data.credits,
            };
          });
          let tPlayer = users || [];
          let result = [...tPlayer,...tempUsers]
          this.selectedUser = result.find(data => data.id == value);
      }
    },
    async viewDialog(value){
      const res = await this.getSubUser(this.$auth.user.id);
      await this.searchData();
      await this.getPartnerProfileData({ id: res.user_id });
      await this.getUserInfo(res.user_id);
      await this.getPartnerSubPartners({id : res.id});
    },
    async search(){
      await this.searchData();
    }
  },
  async created(){
    this.partnerprofile = await this.getSubUser(this.$auth.user.id);
  }
};
</script>
<style>
.cashout {
  background: #0d111b !important;
}
.transfer-cashout-input {
  background: #222f3a !important;
}
.transfer-cashout {
  background: #222f3a !important;
  border-radius: 10px !important;
}
.transfer-cashout-text {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  /* identical to box height */

  text-align: center !important;

  color: #ffffff !important;
}
</style>
<style scoped>
.card {
	border-radius: 25px !important;
	background-color: #0d111b !important;
}
.dialog-btn {
	width: 100px;
	height: 36px;
}
</style>